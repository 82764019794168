import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ApiService } from '../api.service';


@Component({
  selector: 'app-productdetails',
  templateUrl: './productdetails.component.html',
  styleUrls: ['./productdetails.component.css']
})
export class ProductdetailsComponent implements OnInit {

  constructor(private api:ApiService, private route:ActivatedRoute) { }
  colorOption: OwlOptions  = {
    items: 1,
    autoplay: false,
    loop:false,
    margin:0,
    dots: true,
    nav: false
  }
  productDetails: any;
  fid:any;
  varients:any;
  currentVarient = '#ccc';
  productName:any;
  categoryName:any;
  categorySlug:any;
  productDesc:any;
  productBenefits:any;
  productRanges:any;
  Rangeheading: any;
  Rangetext:any;
  bannerImage:any
  SubName:any;
  vImage: any;
  ctext:any;
  cheading:any;
  showVIdeas:boolean=false
  async ngOnInit() {
    this.route.params.subscribe(p => {
      this.fid = p['slug'];
      this.fid =p['subcatslug'];
    });
    this.productDetails = await this.api.getProductBySlug(this.fid);
    if(this.productDetails['product'][0]['category']['slug'].indexOf('interior')>-1) {
      this.showVIdeas=true
    }
    else {
      this.showVIdeas=false
    }
    this.productBenefits = this.productDetails['benefits'];
    this.productBenefits.forEach(b=>b.image=this.api.baseimgurl+b.image)
    this.productRanges = this.productDetails['ranges'];
    this.productRanges.forEach(n=>n.range_image=this.api.baseimgurl+n.range_image)
    this.productDetails = this.productDetails['product']
    this.bannerImage = this.api.baseimgurl+this.productDetails[0].banner_image;
    this.vImage = this.api.baseimgurl+this.productDetails[0].variant_picture;
    // this.productDetails.forEach(p=>{
    //   p.banner_image = this.api.baseimgurl+p.banner_image;
    // })
    this.varients = this.productDetails[0].vibrant_colors.replace(/\s/g, "").split(",");
    this.currentVarient = this.varients[0];
    this.productName = this.productDetails[0]['name'];
    this.SubName = this.productDetails[0]['sub_heading'];
    this.categoryName = this.productDetails[0]['category']['category'];
    this.categorySlug = this.productDetails[0]['category']['slug'];
    this.productDesc = this.productDetails[0]['description'];
    this.Rangeheading = this.productDetails[0]['range_heading'];
    this.Rangetext = this.productDetails[0]['range_text'];
    this.ctext = this.productDetails[0]['variant_text'];
    // this.cheading = this.productDetails[0]['coverage_heading'];
  }
  changeVar(col:any) {
    this.currentVarient = col
  }

}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { ProductsComponent } from './products/products.component';
import { ProductdetailsComponent } from './productdetails/productdetails.component';
import { JoinusComponent } from './joinus/joinus.component';
import { ContactComponent } from './contact/contact.component';
import { VideaComponent } from './videa/videa.component';
import { BlogsComponent } from './blogs/blogs.component';
import { BlogSingleComponent } from './blog-single/blog-single.component';
import { PaintCalculatorComponent } from './paint-calculator/paint-calculator.component';
import { ColorsComponent } from './colors/colors.component';
import { FaqComponent } from './faq/faq.component';
import { BranchLocatorComponent } from './branch-locator/branch-locator.component';
import { DownloadsComponent } from './downloads/downloads.component';
import { PaintguideComponent } from './paintguide/paintguide.component';
import { CertificationsComponent } from './certifications/certifications.component';
import { NewsComponent } from './news/news.component';
import { NewsdetailsComponent } from './newsdetails/newsdetails.component';
import { componentFactoryName } from '@angular/compiler';
import { AllproductsComponent } from './allproducts/allproducts.component';
import { SubcategoryComponent } from './subcategory/subcategory.component';
import { WarrantycardComponent } from './warrantycard/warrantycard.component';


const routes: Routes = [
  {path: '', component: HomeComponent },
  {path: '' , redirectTo:'home',pathMatch:'full'},
  {path: 'about', component: AboutComponent },
  {path: 'blogs', component: BlogsComponent },
  {path: 'blogs/:slug', component: BlogSingleComponent },
  {path: 'products', component: ProductsComponent },
  {path: 'products/:catSlug', component: ProductsComponent },
  {path: 'products/:slug', component: ProductsComponent },
  {path: 'product/:slug', component: ProductdetailsComponent },
  {path: 'vibrant-ideas', component: VideaComponent },
  {path: 'warranty', component: WarrantycardComponent },
  {path: 'joinus', component: JoinusComponent },
  {path: 'contact', component: ContactComponent },
  {path: 'colors', component: ColorsComponent },
  {path: 'faq', component: FaqComponent },
  {path: 'downloads', component: DownloadsComponent },
  {path: 'branch-locator', component: BranchLocatorComponent },
  {path: 'paint-guide', component: PaintguideComponent },
  {path: 'certifications', component: CertificationsComponent },
  {path: 'paint-calculator', component: PaintCalculatorComponent },
  {path: 'allproducts' ,  component: AllproductsComponent},
  // {path: 'news', component: NewsComponent},
  // {path: 'newsdetails', component: NewsdetailsComponent},
   // Please do not write any routes below this
   {path: ':catslug' , component: SubcategoryComponent },
   {path: ':catslug/:subcatslug' , component: ProductdetailsComponent },
   {path: ':catslug/:subcatslug/:productslug' , component: ProductdetailsComponent },
   {path: '**', redirectTo: '/'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import $ from 'jquery';
@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {

  constructor(private api:ApiService) { }
  faqs:any;
  async ngOnInit() {
    this.faqs = await this.api.getFaqs()
    this.faqs.forEach((s:any)=>s.image=this.api.baseimgurl+s.image)
  }
  toggleFaq(i:any) {
    $('.faq-wrap .faq-single:nth-child('+(i+1)+') .f-trigger').toggleClass('triggered');
    $('#faq'+i+' .fbody').slideToggle()
  }
  

}

<section class="headingpainguide p-5">
    <div class="container">
        <div class="col-12 col-sm-12 col-md-8 p-0">
            <h1>Colors According to Vastu</h1>
            <p>Looking for wall colours that fill your house with warmth, positivity, and vibrance? Nexon paints your spot! Check out our beautiful palette- </p>
        </div>
    </div>
</section>

<section class="colorsboxs p-3">
    <div class="container">
        <div class="row">
            <div class="col-12 col-sm-12 col-md-4">
                <img src="assets/images/paint-guide/yellow.png">
                <h3 class="mb-3">Yellow</h3>
                  <p>Summon self-esteem with Yellow. Try shades of yellow as they signify communication and power.
                </p>
            </div>
            <div class="col-12 col-sm-12 col-md-4">
                <img src="assets/images/paint-guide/purple.png">
                <h3 class="mb-3"> Purple</h3>
                <p>Peace with Purple. Try shades of lavender for a calm and soothing sleep</p>
            </div>
            <div class="col-12 col-sm-12 col-md-4">
                <img src="assets/images/paint-guide/green.png">
                <h3 class="mb-3">Green</h3>
                <p>Go green and heal. The colour is associated with wood which tends to cure stress and depression.
                </p>
            </div>
        </div>
    </div>
</section>

<section class="zonewise">
    <div class="container">
        <p class="py-5">Zone Wise Colors According to Vaastu</p>
        <img class="img-fluid" src="assets/images/paint-guide/zone-img.jpg">
    </div>
</section>



<section class="colorphyclogoy py-5 text center">
    <div class="container">
        <!-- <div class="colorheding text-center">Color Pyschology</div> -->
        <img src="assets/images/paint-guide/color pyschology.png" class="img-fluid">
    </div>
</section>

<section class="colorguidebtm py-5">
    <div class="container">
        <div class="paragraph-lead">
            <p class="text-center"><img src="assets/images/paint-guide/colors.svg"> 2200 colors Available </p>
            <a routerLink="/colors" class="btn btn-colorguidebtm">Click Here</a>
        </div>
        <div class="colors-paint text-center">
            <div class="col-12 col-sm-12 col-md-8 offset-2 py-5">
                <h1 class="colorheding">A Feng Shui Colour Guide to transform your home.
                </h1>
                <p>Feng shui means “the way of wind and water.” Feng shui is an ancient Chinese art of designing buildings, objects, and spaces in an atmosphere to gain peace and balance. Feng shui your way in our colour palette and pick the aura for your home! 

                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-sm-12 col-md-4">
                <img src="assets/images/paint-guide/red.png">
                <h3 class="mb-3">Red</h3>
                    <p>Renders love with Red </p>
            </div>
            <div class="col-12 col-sm-12 col-md-4">
                <img src="assets/images/paint-guide/orange.png">
                <h3 class="mb-3"> Orange </h3>
                <p>Opt for optimism with Orange</p>
            </div>
            <div class="col-12 col-sm-12 col-md-4">
                <img src="assets/images/paint-guide/yellow-gold.png">
                <h3 class="mb-3">Yellow/Gold</h3>
                <p>yields power and wisdom with Yellow                </p>
            </div>
            <div class="col-12 col-sm-12 col-md-4">
                <img src="assets/images/paint-guide/pink.png">
                <h3 class="mb-3">Pink</h3>
                <p>Plunge into tenderness and caring with Pink</p>
            </div>
            <div class="col-12 col-sm-12 col-md-4">
                <img src="assets/images/paint-guide/green1.png">
                <h3 class="mb-3">Green</h3>
                <p>Greet generosity with Green </p>
            </div>
            <div class="col-12 col-sm-12 col-md-4">
                <img src="assets/images/paint-guide/blue-green.png">
                <h3 class="mb-3">Blue-Greens</h3>
                <p>Bring harmony with Blue Greens</p>
            </div>
            
            <div class="col-12 col-sm-12 col-md-4">
                <img src="assets/images/paint-guide/deepar-blue.png">
                <h3 class="mb-3">Deeper Blues</h3>
                <p>Draw confidence with Deeper Blues</p>
            </div>
            <div class="col-12 col-sm-12 col-md-4">
                <img src="assets/images/paint-guide/purple1.png">
                <h3 class="mb-3">Purple</h3>
                <p>Permit nobility in your home with Purple </p>
            </div>
            <div class="col-12 col-sm-12 col-md-4">
                <img src="assets/images/paint-guide/black.png">
                <h3 class="mb-3">Black</h3>
                <p>Balance elegance and wealth with Black </p>
            </div>
            <div class="col-12 col-sm-12 col-md-4">
                <img src="assets/images/paint-guide/white.png">
                <h3 class="mb-3">White</h3>
                <p>Witness reverence with White </p>
            </div>
            <div class="col-12 col-sm-12 col-md-4">
                <img src="assets/images/paint-guide/grey.png">
                <h3 class="mb-3">Gray</h3>
                <p>Generate dignity with Gray 
                </p>
            </div>
            <div class="col-12 col-sm-12 col-md-4">
                <img src="assets/images/paint-guide/browne.png">
                <h3 class="mb-3">Brown </h3>
                <p>Browse endurance with Brown.</p>
            </div>
            
        </div>

    </div>
</section>

<app-knowmore></app-knowmore>
import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-videa',
  templateUrl: './videa.component.html',
  styleUrls: ['./videa.component.css']
})
export class VideaComponent implements OnInit {

  constructor() { }
  colorOption: OwlOptions  = {
    items: 1,
    autoplay: false,
    loop:false,
    margin:0,
    dots: true,
    nav: false
  }
  ideas = [
    { 
      'image': 'assets/images/videas/interior.jpg', 
      'cat': 'Interior' ,
      'gallery': ['assets/images/videas/img1.jpg','assets/images/videas/img2.jpg','assets/images/videas/img3.jpg','assets/images/videas/img4.jpg','assets/images/videas/img5.jpg','assets/images/videas/img6.jpg','assets/images/videas/img7.jpg','assets/images/videas/img8.jpg','assets/images/videas/img9.jpg','assets/images/videas/img10.jpg']
    },
    { 
      'image': 'assets/images/videas/exterior.jpg', 
      'cat': 'Exterior',
      'gallery': ['assets/images/videas/img11.jpg','assets/images/videas/img12.jpg','assets/images/videas/img13.jpg','assets/images/videas/img14.jpg',]
    },
    { 
      'image': 'assets/images/videas/balcony.jpg', 
      'cat': 'Balcony',
      'gallery': ['assets/images/videas/img19.jpg','assets/images/videas/img20.jpg','assets/images/videas/img21.jpg']
    },
    { 
      'image': 'assets/images/videas/bedroom.jpg', 
      'cat': 'Bedroom',
      'gallery': ['assets/images/videas/bedroom1.jpg','assets/images/videas/bedroom2.jpg']
    },
    { 
      'image': 'assets/images/videas/dining-room.jpg', 
      'cat': 'Dining room',
      'gallery': ['assets/images/videas/img24.jpg','assets/images/videas/img25.jpg',]
    },
    { 
      'image': 'assets/images/videas/living-room.jpg', 
      'cat': 'Living Room',
      'gallery': ['assets/images/videas/img15.jpg','assets/images/videas/img16.jpg','assets/images/videas/img17.jpg','assets/images/videas/img18.jpg',]
    }
  ]
  selectedItem:any;
  galleryImages:any;
  ngOnInit(): void {
    this.selectedItem = this.ideas[0];
    this.galleryImages = this.ideas[0].gallery;
  }
  getCatImage(item:any) {
    this.selectedItem = item;
    document.getElementById('maincatimg').setAttribute('src',this.selectedItem.image);
    this.galleryImages = this.selectedItem.gallery
  }
  viewGallery(el: HTMLElement){
    // console.log(this.selectedItem);
    el.scrollIntoView();
  }

}

import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import * as $ from 'jquery'; 
import { NavigationEnd, NavigationStart, Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  currentPage:any;
  constructor(private api:ApiService, private router:Router) {
    this.router.events.subscribe((event: NavigationEnd) => {
      if(event instanceof NavigationStart) {
        this.currentPage=event.url
        this.currentPage=this.currentPage.slice(1)
        this.getPageMeta(this.currentPage)
      }
    });

   }
   catList:any;
   isMobile:boolean=false;
  //  getIsMobile(): boolean {
  //   const w = document.documentElement.clientWidth;
  //   const breakpoint = 992;
  //   console.log(w);
  //   if (w < breakpoint) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }
  pageDetails:any
  async ngOnInit() {

    

    if($(window).width()>991) {
      $(".megamenu").hover(function(){
        $(this).find(".dropdown-menu").toggleClass("show");
      });
    }
    // this.isMobile = this.getIsMobile();
    // window.onresize = () => {
    //   this.isMobile = this.getIsMobile();
    // };
    window.onscroll = ()=> { this.scrollFunction(); };
    this.catList = await this.api.getcategories()
    this.catList = this.catList.response;
    this.catList.forEach((s:any)=>s.p_image=this.api.baseimgurl+s.p_image)
    // console.log(this.catList)
    
    $(window).resize(function(){
      if($(window).width()>991) {
        $(".megamenu").hover(function(){
          $(this).find(".dropdown-menu").toggleClass("show");
        });
      }
    })

    $('.toggle-sub').on('click',function(){
      $(this).siblings('.dropdown-menu').slideToggle()
    })
    
    $(".about").hover(function(){
      $(".showabout").toggleClass("show");
    });
    $(".contact").hover(function(){
      $(".showcontact").toggleClass("show");
    });
    
  }
  getPageMeta(url:any){
    this.api.MetaAllPages(url).subscribe(a=>{
    })
  }
  scrollFunction() {
    var headerheight = document.querySelectorAll('header')[0].offsetHeight;
    if (document.body.scrollTop > headerheight|| document.documentElement.scrollTop > headerheight){
        document.querySelector('header').classList.add('header_fixed');
    }
    else if (document.body.scrollTop <= headerheight|| document.documentElement.scrollTop <= headerheight){
        document.querySelector('header').classList.remove('header_fixed');
    
    }
  }

}

import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ApiService } from '../api.service';
import * as $ from 'jquery'; 
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(private api:ApiService) { }
  bannerOption: OwlOptions  = {
    items: 1,
    autoplay: true,
    loop:false,
    margin:0,
    dots: true,
    nav: false
  }
  colorOption: OwlOptions  = {
    items: 1,
    autoplay: false,
    loop:false,
    margin:0,
    dots: true,
    nav: false
  }
  ProductRange: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    center: true,
    // autoplay: true,
    dots: false,
    navSpeed: 700,
    navText: ['<img src="assets/images/home/preview.svg" alt="pre">', '<img src="assets/images/home/next.svg" alt="nexttt">'],
    responsive: {
      0: {
        items: 1
      },
      
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 3
      }
    },
    nav: true
  }
  rangeOption: OwlOptions  = {
    items: 1,
    autoplay: false,
    loop:true,
    margin:0,
    dots: false,
    nav: false,
    center: true,
    // navText: ['<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>'],
    responsive:{
        0:{
          items:1,
          center: true
        },
        768: {
          items: 2,
          center: false
        },
        992:{
          items:3,
          center: true
        }
    }
  }
  productRange = [
    {
      'name': 'interior',
      'images': ['assets/images/home/product-range-img1.png', 'assets/images/home/product-range-img2.png', 'assets/images/home/product-range-img3.png']
    },
    {
      'name': 'exterior',
      'images': ['assets/images/home/product-range-img4.png', 'assets/images/home/product-range-img5.png', 'assets/images/home/product-range-img6.png']
    },
    {
      'name': 'wallputty',
      'images': ['assets/images/home/product-range-img7.png', 'assets/images/home/product-range-img8.png', 'assets/images/home/product-range-img9.png']
    },
    {
      'name': 'primers',
      'images': ['assets/images/home/product-range-img10.png', 'assets/images/home/product-range-img11.png', 'assets/images/home/product-range-img12.png']
    },
    {
      'name': 'woodfinishes',
      'images': ['assets/images/home/product-range-img13.png', 'assets/images/home/product-range-img14.png', 'assets/images/home/product-range-img15.png']
    }
  ]
  rangeImages: any;
  Homebanner: any;
  resized:boolean=false;
  async ngOnInit(){
    this.rangeImages = this.productRange[0].images;
    var toggleEle = document.querySelectorAll('#eco-count .countchage');
    if(toggleEle.length>0) {
      toggleEle.forEach(d=>{
        d.addEventListener('click',(e)=>{
          var t = d.getAttribute('data-target');
          toggleEle.forEach(f=>{
            f.classList.remove('active');
          })
          d.classList.add('active');
          // document.querySelectorAll('#Eco-Friendly-img .changeimgeco').forEach(x=>{
          //   x.classList.add('hidden');
          // })
          // document.querySelectorAll('#'+t)[0].classList.remove('hidden');
        })
      })
    }

   

    var toggleEle2 = document.querySelectorAll('#eco-count2 .countchage');
    if(toggleEle2.length>0) {
      toggleEle2.forEach(d=>{
        d.addEventListener('click',(e)=>{
          var t = d.getAttribute('data-target');
          toggleEle2.forEach(f=>{
            f.classList.remove('active');
          })
          d.classList.add('active');
          document.querySelectorAll('#Eco-Friendly-img2 .changeimgeco').forEach(x=>{
            x.classList.add('hidden');
          })
          document.querySelectorAll('#'+t)[0].classList.remove('hidden');
        })
      })
    }
    window.onresize = () => {
      this.resized=true
      setTimeout(() => {
        this.resized=false
      }, 10);
    };
   
    this.Homebanner = await this.api.getHomebanner()
    this.Homebanner=this.Homebanner['response']
    this.Homebanner.map((s:any)=>s.computer_img=this.api.baseimgurl+s.computer_img)
    this.Homebanner.map((s:any)=>s.mobile_img=this.api.baseimgurl+s.mobile_img)

    document.addEventListener('play', function(e){

    }, true);
  }

  displayStyle = "none";
  openPopup() {
    this.displayStyle = "block";
    var audios = document.getElementsByTagName('video');
    for(var i = 0, len = audios.length; i < len;i++){
        audios[i].play();
    }
  }
  closePopup() {
    this.displayStyle = "none";
    var audios = document.getElementsByTagName('video');
    for(var i = 0, len = audios.length; i < len;i++){
        audios[i].pause();
        audios[i].currentTime=0;
    }
  }
  


  

  changeColorBG(color:any, index) {
    document.querySelector('#color-slider-main').setAttribute('style','background-color:'+color);
    document.querySelectorAll('.color-tab li').forEach(c=>{
      c.classList.remove('active');
    })
    document.querySelector('.color-tab li:nth-child('+index+')').classList.add('active')
  }
  getRange(i:any){
    this.rangeImages = this.productRange[i].images;
  }

}

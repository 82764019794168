<section class="ideas position-relative">
    <div class="extra-float"><div class="container px-5"><div class="float-inner col-12"></div></div></div>
    <div class="container position-relative">
        <div class="about-title-wrap justify-content-between align-items-end">
            <img src="assets/images/home/bucket-model3.png" alt="Spilled Bucket" class="about-title-img">
            <div class="about-title px-5">
                <h2>Vibrant</h2>
                <p>Ideas</p>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-6 col-md-10 text-center vsub">
                Get Inspired by Vibrant ideas for your interior, exterior design and much more through our Gallery
            </div>
        </div>
    </div>
    <div class="ideas-slider position-relative">
        <div class="main-gal position-relative">
            <img src="assets/images/home/ideas-img.jpg" id="maincatimg" class="full" alt="Idea 1">
            <button class="view-gallery-btn" (click)="viewGallery(gallery)">
                <img src="assets/images/videas/gal.png" alt="gal">
                View Gallery
            </button>
        </div>
        <ul class="gal-cats">
            <ng-container *ngFor="let id of ideas; let i=index">
                <li [ngClass]="{'active': selectedItem == id}" (click)="getCatImage(id)">{{id.cat}}</li>
            </ng-container>
        </ul>
    </div>
    <div class="container mt-4">
        <div class="row mt-4">
            <div class="col-lg-6 d-flex align-items-center justify-content-lg-end">
                <img src="assets/images/home/colors.png" alt="Colors" class="ml-3 mr-2">
                <h3 class="color-head">2200 colors Available</h3>
            </div>
            <div class="col-lg-6 mt-4 mt-lg-0">
                <a routerLink="/colors" class="commmon-big-btn light">Click Here</a>
            </div>
        </div>
    </div>
</section>

<section class="room-list py-5">
    <div class="container">
        <div class="d-md-flex flex-nowrap">
            <div class="sticky-cats">
                <ul class="gal-cats">
                    <ng-container *ngFor="let id of ideas; let i=index">
                        <li [ngClass]="{'active': selectedItem == id}" (click)="getCatImage(id)">{{id.cat}}</li>
                    </ng-container>
                </ul>
            </div>
            <div class="cat-gallery" #gallery>
                <div class="row">
                    <ng-container *ngFor="let gal of galleryImages">
                        <div class="col-md-6 mb-4">
                            <div class="room-showcase"><img [src]="gal" alt="Room" class="img-fluid full"></div>
                        </div>
                    </ng-container>
                </div>
                <div class="text-center mt-4">
                    <a href="#" class="commmon-big-btn primary">Load More</a>
                </div>
            </div>
        </div>
    </div>
</section>
<app-knowmore></app-knowmore>


<!-- <section class="ideas position-relative">
    <div class="container">
    <div class="extra-float">
        <div class="container px-5"></div></div>
        <h2>Vibrant Ideas </h2>
    <div class="ideas-slider position-relative">
        <owl-carousel-o [options]="colorOption">
            <ng-template carouselSlide>
                <img src="assets/images/productdetails/videa.jpg" class="full" alt="Idea 1">
            </ng-template>
            <ng-template carouselSlide>
                <img src="assets/images/productdetails/videa.jpg" class="full" alt="Idea 1">
            </ng-template>
            <ng-template carouselSlide>
                <img src="assets/images/productdetails/videa.jpg" class="full" alt="Idea 1">
            </ng-template>
            <ng-template carouselSlide>
                <img src="assets/images/productdetails/videa.jpg" class="full" alt="Idea 1">
            </ng-template>
            <ng-template carouselSlide>
                <img src="assets/images/productdetails/videa.jpg" class="full" alt="Idea 1">
            </ng-template>
            <ng-template carouselSlide>
                <img src="assets/images/productdetails/ideas-img.jpg" class="full" alt="Idea 1">
            </ng-template>
        </owl-carousel-o>
        <div class="text-center text-md-right mt-4 img-cen">
            Palette : <a href="#"><img src="assets/images/productdetails/change1.jpg" alt="Idea 1"></a>
            <a href="#"><img src="assets/images/productdetails/change2.jpg" alt="Idea 1"></a>
            <a href="#"><img src="assets/images/productdetails/change3.jpg" alt="Idea 1"></a>
            <a href="#"><img src="assets/images/productdetails/change4.jpg" alt="Idea 1"></a>
            <a href="#"><img src="assets/images/productdetails/change5.jpg" alt="Idea 1"></a>
        </div>
    </div>
    </div>
</section> -->
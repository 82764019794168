<div class="container">
    <ul class="blog-breadcrumb">
        <li><a routerLink="/">Home</a></li>
        <li><a routerLink="/news">News</a></li>
        <li>When is the right time to paint your exteriors?</li>
    </ul>
</div>
<div class="blog-main">
    <div class="container mb-5">
        <div class="row">
            <div class="col-lg-9 col-md-8">
                <div class="blog-grid">
                    <img src="assets/images/blog-img.jpg" alt="Blog 1" class="full">
                    <div class="blg-cap d-flex justify-content-between">
                        <span class="d-flex align-items-center"><i class="fa fa-thumbs-o-up mr-2"></i>245 Likes</span>
                        <span class="d-flex align-items-center"><i class="fa fa-comment-o mr-2"></i>15 Comments</span>
                    </div>
                    <ul class="blg-tags"><li>Interior Design</li><li>Lorem Ipsum</li></ul>
                    <h4 class="blog-title">When is the right time to paint your exteriors?</h4>
                    <div class="blog-meta">
                        <span class="mr-3"><i class="fa fa-user-o mr-2"></i>Nexon Paints</span>
                        <span><i class="fa fa-calendar-o mr-2"></i>17-05-2021</span>
                    </div>
                    <div class="blog-body mt-4">
                        <p>A study has shown that colors can significantly affect our behavior and moods, so making sure that you have the right colors for the decor and rooms in your home is essential. If you have been a funk or looking for much more excitement in your life, adding some color or painting a wall to your home might be just what you require.</p>

                        <p>Here's the ultimate color guide for what tints you shouldn't and should use for each area of your home:</p>

                        <p>Dining Room or Kitchen:</p>

                        <p>Did you know that orange, red, and yellow stimulate your appetite? When a person eats on a red plate, are more likely to eat more calories! This is why many fast food centers have orange, yellow, and red all over their restaurants and in their logo—they want you to eat, eat, and eat. So, never paint your kitchen or dining room orange, yellow or red and avoid primary shades in these colors around food. Cream or white, on the other hand, are the colors of goodness and cleanliness two characteristics you want around food.<br>
                        Wrong Colors: Orange, Yellow, Red<br>
                        Great Colors: Cream or White</p>

                        <p>The study or office:</p>

                        <p>A researcher foundthat when employees worked in blue offices, they felt the calmest, hopeful and centered, towards their work and made fewer mistakes than working in a white or cream office. Blue is the right color for your office shades, carpet, or walls because it lowers your heart rate and evokes wisdom, which is precisely why workers tend to make less mistakes. Grey is one of the wrong colors you can choose for productivity because it is the most passive color and lacks the most energy.<br>
                        Great Color: Blue<br>
                        Bad Color: Grey</p>

                        <p>Living Room:</p>

                        <p>Since you likely spend time watching TV and relaxing in your living room, green is the best color choice for 2 reasons. First reason, it makes people feel calm and safe, which is precisely how you want to think at the end of a stressful day at work. The second reason, the color green is restful on the eyes and produces the least amount of eyestrain, making this a right choice if you want to sit in front of the TV for a while. Orange is an exciting color, so it is not an excellent choice to use in a room where you want to spend time relaxing.<br>
                        Great Color: Green<br>
                        Bad Color: Orange</p>

                        <p>Master Bedroom:</p>

                        <p>Researchers have proved that black is the color of power or aggression. This is the opposite effect you want to have in a bedroom where harmony and equality are essential. White sheets also remind people of a sterile hospital bed not high for the passion you want in the bedroom. The right color to use in the bedroom is purple, which is the color of comfort, luxury, and sumptuousness.<br>
                        Great Color: Purple<br>
                        Bad Color: Black or white</p>

                        <p>The Porch:</p>

                        <p>In the South, you usually see porches painted with the famous, "haint blue." This is due to the Southern Gullah tradition that says this color wards off evil spirits and supposedly repels spiders and wasps. Although Brown is a constant color, it is not great for a porch as it leads to making things look old.<br>
                        Great Color: Haint Blue<br>
                        Bad Color: Brown</p>

                        <p>Workout Room:</p>

                        <p>If you have a workout or gym room, orange is the best color to use because it is the color of enthusiasm and stimulation. Research has found that orange grows oxygen supply to the brain, which stimulates brain activity and produces an energizing effect. So, get out that orange spandex when you hit the Zumba! Grey is the opposite of orange because it is the least energetic color and would be a terrible motivator in a home gym.<br>
                        Great Color: Orange<br>
                        Bad Color: Grey</p>

                        <p>Bathroom:</p>

                        <p>Yellow is a happy color, according to Feng Shui philosophy, assists our endocrine system, stimulates proper nerve function and liver to function optimally. To have smooth working digestion and Sunny morning since the bathroom is usually the first place you go, yellow is the best color. Black would is a bad idea for a small room like the bathroom as it would make the room look even smaller and boxed in.<br>
                        Bad Color: Black<br>
                        Good Color: Yellow</p>

                        <p>Colors can affect moods in different areas in the house. But, of course, colors aren't the only affects how people see one can still be efficient in a grey office or eat less on a red plate. But when given a choice, picking a color that will work with you, and not against you, can only help.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-4">
                <div class="blg-sidebar">
                    <div class="side-title d-flex justify-content-between">
                        <span>Filters</span>
                        <span>Clear All</span>
                    </div>
                    <select name="" id="" class="form-control filter-control">
                        <option value="Latest" selected>Latest</option>
                        <option value="Oldest">Oldest</option>
                    </select>
                    <select name="" id="" class="form-control filter-control">
                        <option value="Latest" selected>Category</option>
                        <option value="Oldest">Oldest</option>
                    </select>
                    <select name="" id="" class="form-control filter-control">
                        <option value="Latest" selected>Area</option>
                        <option value="Oldest">Oldest</option>
                    </select>
                    <select name="" id="" class="form-control filter-control">
                        <option value="Latest" selected>Archieves</option>
                        <option value="Oldest">Oldest</option>
                    </select>
                    <div class="side-title d-flex justify-content-between mt-3">
                        <span>Tags</span>
                    </div>
                    <ul class="sidetags">
                        <li>Lorem Ipsum</li>
                        <li>Lorem Ipsum</li>
                        <li>Lorem Ipsum</li>
                        <li>Lorem Ipsum</li>
                        <li>Lorem Ipsum</li>
                        <li>Lorem Ipsum</li>
                        <li>Lorem Ipsum</li>
                        <li>Lorem Ipsum</li>
                        <li>Lorem Ipsum</li>
                        <li>Lorem Ipsum</li>
                        <li>Lorem Ipsum</li>
                        <li>Lorem Ipsum</li>
                        <li>Lorem Ipsum</li>
                        <li>Lorem Ipsum</li>
                        <li>Lorem Ipsum</li>
                        <li>Lorem Ipsum</li>
                        <li>Lorem Ipsum</li>
                        <li>Lorem Ipsum</li>
                        <li>Lorem Ipsum</li>
                        <li>Lorem Ipsum</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="blog-comments">
        <div class="container">
            <h5><strong>Comments</strong></h5>
        </div>
        <div class="comment-wrap mt-4">
            <div class="container">
                <div class="comment-single">
                    <div class="row">
                        <div class="col-lg-6 col-md-8 d-flex align-items-start">
                            <img src="assets/images/person.png" alt="" class="mr-3">
                            <div class="comment-body">
                                <div class="user">Username</div>
                                <div class="date">28/09/2021</div>
                                <p class="cbody">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce nulla arcu in aliquet felis posuere sagittis odio. Volutpat venenatis, diam ornare purus eget molestie ultricies enim. Vulputate risus, amet odio feugiat phasellus.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="comment-single">
                    <div class="row">
                        <div class="col-lg-6 col-md-8 d-flex align-items-start">
                            <img src="assets/images/person.png" alt="" class="mr-3">
                            <div class="comment-body">
                                <div class="user">Username</div>
                                <div class="date">28/09/2021</div>
                                <p class="cbody">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce nulla arcu in aliquet felis posuere sagittis odio. Volutpat venenatis, diam ornare purus eget molestie ultricies enim. Vulputate risus, amet odio feugiat phasellus.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="comment-single">
                    <div class="row">
                        <div class="col-lg-6 col-md-8 d-flex align-items-start">
                            <img src="assets/images/person.png" alt="" class="mr-3">
                            <div class="comment-body">
                                <div class="user">Username</div>
                                <div class="date">28/09/2021</div>
                                <p class="cbody">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce nulla arcu in aliquet felis posuere sagittis odio. Volutpat venenatis, diam ornare purus eget molestie ultricies enim. Vulputate risus, amet odio feugiat phasellus.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="reply-main mb-5">
        <h4 class="reply-title">Leave a Reply</h4>
        <div class="reply-form">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 offset-lg-1">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <textarea class="form-control" placeholder="You Comment"></textarea>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Name">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <input type="email" class="form-control" placeholder="E-mail">
                                </div>
                            </div>
                            <div class="col-md-12 mt-5">
                                <button class="commmon-big-btn primary">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-knowmore></app-knowmore>
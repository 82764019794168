import { Component, OnInit } from '@angular/core';
import { NavigationEnd } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { ApiService } from './api.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  firstid:any;
  sub:any;
  constructor( private api:ApiService, private titleService:Title, private metaService:Meta, public route: ActivatedRoute, private router:Router ) {
    // this.sub = this.route.params.subscribe(params => {
    //   this.firstid = params['metaSlug'];
    // });
   }
  metadetails:any;
  MetaList:any;
  ngOnInit() {
    this.router.events.subscribe((evt) => {
        if (!(evt instanceof NavigationEnd)) {
            return;
        }
        window.scrollTo(0, 0)
    });

    // this.MetaList = await this.api.MetaAllPages(this.firstid)
   
    //   this.MetaList = this.MetaList['response'][0]
    //   this.titleService.setTitle(this.MetaList.meta_title);
    //   this.metaService.addTags([
    //     {name: 'keywords', content: 'this.MetaList.meta_keywords'},
    //     {name: 'description', content: 'this.MetaList.meta_description'},
    //     {name: 'robots', content: 'index, follow'},
    //     {name: 'og:image', content: 'https://nexon.antsdigital.in/'+this.MetaList.image}

    //   ]);
    // })
  }
}

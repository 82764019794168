<section class="banner">
    <div class="banner-slider dashed-dotts" *ngIf="!resized">
        <owl-carousel-o [options]="bannerOption">
            <ng-container *ngFor="let slide of Homebanner;let i=index">
                <ng-template carouselSlide id="slide{{i}}">
                    <picture class="w-100">
                        <source media="(min-width:575px)" [srcset]="slide?.computer_img">
                        <source media="(min-width:320px)" [srcset]="slide?.mobile_img">
                        <img [src]="slide?.computer_img" alt="banner-images-nexon" class="full">
                      </picture>
                </ng-template>
            </ng-container>
      </owl-carousel-o>
    </div>
</section>

<section class="about-sec">
    <div class="container">
        <div class="about-title-wrap">
            <div class="about-title">
                <h2>Welcome to</h2>
                <p>Nexon Paints</p>
            </div>
            <img src="assets/images/home/spilled-bucket.png" alt="Spilled Bucket" class="about-title-img">
        </div>
        <div class="row text-center">
            <div class="col-xl-12">
                <p class="about-desc">Started with an aim to manufacture high quality, easy to afford products, Nexon is a fast growing company. Formerly known as Saicoat Paints, the company has acquired a decent share in the market as well as a strong base of happy customers.  Now we are all set to take the next big leap. For doing so, we have incorporated a sequence of changes which include – adoption of an internationally appealing identity, inclusion of a professional management structure and a new team of sharp minds with acute business sense.</p>
            </div>
        </div>
        <!-- <div class="productvideo py-3">
            <div class="videothimb">
                <img (click)="openPopup()" class="img-fluid" src="assets/images/home/nexon_banner.PNG">
            </div>
            <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyle}">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                    <div class="modal-body">
                        <div class="text-right imgvideo py-3"><img (click)="closePopup()" src="assets/images/home/close.svg"></div>
                        <video width="100%" height="auto" loop="true" controls muted id="myVideo"><source src="assets/images/home/nexon_video.mp4" type="video/mp4"></video>
                    </div>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</section>

<section class="color-guide pb-4">
    <div class="container">
        <div class="color-title-wrap">
            <div class="color-title text-right">
                <h2>Color</h2>
                <p>Guide</p>
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-11 offset-lg-1 d-flex flex-wrap align-items-center pt-4">
                <div class="color-tabs">
                    <h4 class="color-heading">Pick any color</h4>
                    <ul class="color-tab">
                        <li (click)="changeColorBG('#CBCAC8',1)" style="background-color: #CBCAC8;"></li>
                        <li (click)="changeColorBG('#2C5D55',2)" class="active" style="background-color: #2C5D55;"></li>
                        <li (click)="changeColorBG('#537F77',3)" style="background-color: #537F77;"></li>
                        <li (click)="changeColorBG('#6A5842',4)" style="background-color: #6A5842;"></li>
                        <li (click)="changeColorBG('#5A615F',5)" style="background-color: #5A615F;"></li>
                    </ul>
                </div>
                <div class="color-slider">
                    <div id="color-slider-main" style="background-color: #2C5D55;">
                        <owl-carousel-o [options]="colorOption">
                            <ng-template carouselSlide>
                                <img src="assets/images/home/trans-wall.png" class="full" alt="Banner 1">
                            </ng-template>
                        </owl-carousel-o>
                    </div>
                    <div class="row mt-4">
                        <div class="col-md-7 d-flex align-items-center">
                            <img src="assets/images/home/colors.png" alt="Colors" class="ml-3 mr-2">
                            <h3 class="color-head">2200 colors Available</h3>
                        </div>
                        <div class="col-md-5">
                            <a routerLink="/colors" class="commmon-big-btn light">Click Here</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-knowmore></app-knowmore>

<section class="product-range position-relative pt-5">
    <div class="extra-float"><div class="container px-5"><div class="float-inner col-12"></div></div></div>
    <div class="container position-relative">
        <div class="about-title-wrap justify-content-between align-items-center">
            <div class="about-title">
                <h2>Explore our</h2>
                <p>Product Range</p>
            </div>
            <img src="assets/images/home/bucket-model.png" alt="Spilled Bucket" class="about-title-img">
        </div>
    </div>    
    <!-- <div class="px-4 position-relative">
        <div class="product-range-inner px-0 px-md-5 mx-0 mx-md-5">
            <ul class="range-list px-0 mx-0 mx-md-5 d-md-flex justify-content-between" id="eco-count">
                <li class="countchage active" data-target="pro1" (click)="getRange(0)"><span>Interior Emulsion</span></li>
                <li class="countchage" data-target="pro2" (click)="getRange(1)"><span>Exterior Emulsion</span></li>
                <li class="countchage" data-target="pro3" (click)="getRange(2)"><span>Wall Putty and Texture</span></li>
                <li class="countchage" data-target="pro4" (click)="getRange(3)"><span>Primers and Thinners</span></li>
                <li class="countchage" data-target="pro5" (click)="getRange(4)"><span>Wood Finishes & <br/>Specialty Emulsion</span></li>
            </ul>
        </div>
    </div> -->
</section>
<!-- 
<section class="range-slider-wrap pt-5">
    <div class="container">
        <div class="px-5 range-slider" id="pro1">
            <owl-carousel-o [options]="rangeOption">
                <ng-container *ngFor="let slide of rangeImages; let i=index">
                    <ng-template carouselSlide id="slide{{i}}">
                      <img [src]="slide" alt="">
                    </ng-template>
                  </ng-container>
            </owl-carousel-o>
        </div>
        <div class="text-center text-lg-right know-more-btn">
            <a routerLink="/products" class="commmon-big-btn primary">View All</a>
        </div>
    </div>
</section> -->

<section class="ourprodectrange">
    <div class="container">
        <owl-carousel-o [options]="ProductRange">
            <ng-template carouselSlide class="fedd">
              <div class="slide"> <p><img src="assets/images/productrange/interior-emulsions.jpg"></p>  
              <div class="text-center ecobtn-center"> <a routerLink="/interior-emulsions" class="btn btn-productrange">Interior Emulsions</a></div>
            </div> 
            </ng-template>  
            <ng-template carouselSlide class="fedd"> 
                <div class="slide"> <p><img src="assets/images/productrange/exterior-emulsion.jpg"></p> 
                    <div class="text-center ecobtn-center"><a routerLink="/exterior-emulsions" class="btn btn-productrange">Exterior Emulsions</a></div>
                 </div> 
            </ng-template>  
            <ng-template carouselSlide class="fedd"> 
                <div class="slide"> <p><img src="assets/images/productrange/undercoats.jpg"></p> 
                    <div class="text-center ecobtn-center"> <a routerLink="/undercoats" class="btn btn-productrange">Undercoats</a></div>
                 </div> 
            </ng-template> 
            <ng-template carouselSlide class="fedd"> 
                <div class="slide"> <p><img src="assets/images/productrange/speciality-emulsion.jpg"></p>
                    <div class="text-center ecobtn-center"> <a routerLink="/specialty-emulsions" class="btn btn-productrange">Specialty Emulsions</a></div>
                </div> 
            </ng-template> 
            <ng-template carouselSlide class="fedd"> 
                <div class="slide"> <p><img src="assets/images/productrange/enamel.jpg"></p>  
                    <div class="text-center ecobtn-center"><a routerLink="/enamel" class="btn btn-productrange">Enamel</a></div>
                </div> 
            </ng-template> 
            <ng-template carouselSlide class="fedd"> 
                <div class="slide"> <p><img src="assets/images/productrange/wood-finishes.jpg"></p> 
                    <div class="text-center ecobtn-center">  <a routerLink="/wood-finishes" class="btn btn-productrange">Wood Finishes</a></div>
                </div> 
            </ng-template> 
            <ng-template carouselSlide class="fedd"> 
                <div class="slide"> <p><img src="assets/images/productrange/metal-finishes.jpg"></p> 
                    <div class="text-center ecobtn-center"> <a routerLink="/metal-primers" class="btn btn-productrange">Metal Primers</a></div>
                </div> 
            </ng-template> 
            <ng-template carouselSlide class="fedd"> 
                <div class="slide"> <p><img src="assets/images/productrange/exterior-textures.jpg"></p>  
                    <div class="text-center ecobtn-center"> <a routerLink="/exterior-texture" class="btn btn-productrange">Exterior Texture</a></div>
                </div> 
            </ng-template> 
          </owl-carousel-o>
    </div>
</section>

<!-- TESTIMONIALS -->
<section class="testimonials">
	<div class="container">

      <div class="row">
        <div class="col-sm-12">
          <div id="customers-testimonials" class="owl-carousel">

            <!--TESTIMONIAL 1 -->
            <div class="item">
              <div class="shadow-effect">
                <img class="img-circle" src="http://themes.audemedia.com/html/goodgrowth/images/testimonial3.jpg" alt="">
                <p>Dramatically maintain clicks-and-mortar solutions without functional solutions. Completely synergize resource taxing relationships via premier niche markets. Professionally cultivate.</p>
              </div>
              <div class="testimonial-name">EMILIANO AQUILANI</div>
            </div>
            <!--END OF TESTIMONIAL 1 -->
            <!--TESTIMONIAL 2 -->
            <div class="item">
              <div class="shadow-effect">
                <img class="img-circle" src="http://themes.audemedia.com/html/goodgrowth/images/testimonial3.jpg" alt="">
                <p>Dramatically maintain clicks-and-mortar solutions without functional solutions. Completely synergize resource taxing relationships via premier niche markets. Professionally cultivate.</p>
              </div>
              <div class="testimonial-name">ANNA ITURBE</div>
            </div>
            <!--END OF TESTIMONIAL 2 -->
            <!--TESTIMONIAL 3 -->
            <div class="item">
              <div class="shadow-effect">
                <img class="img-circle" src="http://themes.audemedia.com/html/goodgrowth/images/testimonial3.jpg" alt="">
                <p>Dramatically maintain clicks-and-mortar solutions without functional solutions. Completely synergize resource taxing relationships via premier niche markets. Professionally cultivate.</p>
              </div>
              <div class="testimonial-name">LARA ATKINSON</div>
            </div>
            <!--END OF TESTIMONIAL 3 -->
            <!--TESTIMONIAL 4 -->
            <div class="item">
              <div class="shadow-effect">
                <img class="img-circle" src="http://themes.audemedia.com/html/goodgrowth/images/testimonial3.jpg" alt="">
                <p>Dramatically maintain clicks-and-mortar solutions without functional solutions. Completely synergize resource taxing relationships via premier niche markets. Professionally cultivate.</p>
              </div>
              <div class="testimonial-name">IAN OWEN</div>
            </div>
            <!--END OF TESTIMONIAL 4 -->
            <!--TESTIMONIAL 5 -->
            <div class="item">
              <div class="shadow-effect">
                <img class="img-circle" src="http://themes.audemedia.com/html/goodgrowth/images/testimonial3.jpg" alt="">
                <p>Dramatically maintain clicks-and-mortar solutions without functional solutions. Completely synergize resource taxing relationships via premier niche markets. Professionally cultivate.</p>
              </div>
              <div class="testimonial-name">MICHAEL TEDDY</div>
            </div>
            <!--END OF TESTIMONIAL 5 -->
          </div>
        </div>
      </div>
      </div>
    </section>
    <!-- END OF TESTIMONIALS -->

<section class="eco-friend position-relative py-5">
    <div class="extra-float"><div class="container px-5"><div class="float-inner col-12"></div></div></div>
    <div class="container position-relative">
        <div class="about-title-wrap justify-content-between align-items-end">
            <div class="about-title">
                <h2>We Make</h2>
                <p>Eco-Friendly Paints</p>
            </div>
            <img src="assets/images/home/bucket-model2.png" alt="Spilled Bucket" class="about-title-img">
        </div>
    </div>
    <div class="container position-relative">
        <div class="px-0 px-md-5">
            <div class="row mb-4">
                <div class="col-lg-8 offset-lg-2">
                    <p class="about-desc text-center">At Nexon Paints, we are environment friendly and believe in nurturing the ecosystem. Thus we thoroughly promote non-polluting water based paints.</p>  
                </div>
            </div>
            <div class="row align-items-center" id="eco-count2">
                <div class="col-lg-4 px-0 text-center text-lg-right">
                    <ul class="eco-count left">
                        <li class="countchage active" data-target="bkt1">1</li>
                        <li class="countchage" data-target="bkt2">2</li>
                        <li class="countchage" data-target="bkt3">3</li>
                    </ul>
                </div>
                <div class="col-lg-8">
                    <div class="row flex-lg-row-reverse align-items-center">
                        <div class="col-lg-6 px-0 text-center text-lg-left">
                            <ul class="eco-count right">
                                <li class="countchage" data-target="bkt4">4</li>
                                <li class="countchage" data-target="bkt5">5</li>
                                <li class="countchage" data-target="bkt6">6</li>
                                <li class="countchage" data-target="bkt7">7</li>
                            </ul>
                        </div>
                        <div class="col-lg-6 px-0" id="Eco-Friendly-img2">
                            <img id="bkt1" class="main-eco full changeimgeco" src="assets/images/home/eco-box.png" alt="" >
                            <img id="bkt2" class="main-eco full changeimgeco hidden" src="assets/images/home/eco-box2.png" alt="" >
                            <img id="bkt3" class="main-eco full changeimgeco hidden" src="assets/images/home/eco-box3.png" alt="" >
                            <img id="bkt4" class="main-eco full changeimgeco hidden" src="assets/images/home/eco-box4.png" alt="" >
                            <img id="bkt5" class="main-eco full changeimgeco hidden" src="assets/images/home/eco-box5.png" alt="" >
                            <img id="bkt6" class="main-eco full changeimgeco hidden" src="assets/images/home/eco-box6.png" alt="" >
                            <img id="bkt7" class="main-eco full changeimgeco hidden" src="assets/images/home/eco-box7.png" alt="" >
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="eco-foot position-relative">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-6 text-center text-md-left">
                    <div class="d-flex align-items-center">
                        <img src="assets/images/home/eco-foot-img.png" class="pr-3" alt="">
                        <span class="second-text">Green Pro Certified Products</span>
                    </div>
                </div>
                <div class="col-md-6 text-center text-md-right">
                    <a routerLink="/certifications" class="commmon-big-btn secondary">View Certificates</a>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="ideas position-relative">
    <div class="extra-float"><div class="container px-5"><div class="float-inner col-12"></div></div></div>
    <div class="container position-relative">
        <div class="about-title-wrap justify-content-between align-items-end">
            <img src="assets/images/home/bucket-model3.png" alt="Spilled Bucket" class="about-title-img">
            <div class="about-title px-5">
                <h2>Vibrant</h2>
                <p>Ideas</p>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-6 col-md-10 text-center vsub">
                Get Inspired by Vibrant ideas for your interior, exterior design and much more through our Gallery
            </div>
        </div>
    </div>
    <div class="ideas-slider position-relative">
        <owl-carousel-o [options]="colorOption">
            <ng-template carouselSlide>
                <img src="assets/images/home/ideas-img1.jpg" class="full" alt="Idea 1">
            </ng-template>
            <ng-template carouselSlide>
                <img src="assets/images/home/ideas-img2.jpg" class="full" alt="Idea 2">
            </ng-template>
            <ng-template carouselSlide>
                <img src="assets/images/home/ideas-img3.jpg" class="full" alt="Idea 3">
            </ng-template>
            <ng-template carouselSlide>
                <img src="assets/images/home/ideas-img4.jpg" class="full" alt="Idea 4">
            </ng-template>
            <ng-template carouselSlide>
                <img src="assets/images/home/ideas-img5.jpg" class="full" alt="Idea 5">
            </ng-template>
        </owl-carousel-o>
        <div class="text-center text-md-right mt-4">
            <a routerLink="/vibrant-ideas" class="commmon-big-btn primary">Explore Gallery</a>
        </div>
    </div>
</section>



<!-- TESTIMONIALS -->
<section class="testimonials">
	<div class="container">

      <div class="row">
        <div class="col-sm-12">
          <div id="customers-testimonials" class="owl-carousel">

            <!--TESTIMONIAL 1 -->
            <div class="item">
              <div class="shadow-effect">
                <img class="img-circle" src="http://themes.audemedia.com/html/goodgrowth/images/testimonial3.jpg" alt="">
                <p>Dramatically maintain clicks-and-mortar solutions without functional solutions. Completely synergize resource taxing relationships via premier niche markets. Professionally cultivate.</p>
              </div>
              <div class="testimonial-name">EMILIANO AQUILANI</div>
            </div>
            <!--END OF TESTIMONIAL 1 -->
            <!--TESTIMONIAL 2 -->
            <div class="item">
              <div class="shadow-effect">
                <img class="img-circle" src="http://themes.audemedia.com/html/goodgrowth/images/testimonial3.jpg" alt="">
                <p>Dramatically maintain clicks-and-mortar solutions without functional solutions. Completely synergize resource taxing relationships via premier niche markets. Professionally cultivate.</p>
              </div>
              <div class="testimonial-name">ANNA ITURBE</div>
            </div>
            <!--END OF TESTIMONIAL 2 -->
            <!--TESTIMONIAL 3 -->
            <div class="item">
              <div class="shadow-effect">
                <img class="img-circle" src="http://themes.audemedia.com/html/goodgrowth/images/testimonial3.jpg" alt="">
                <p>Dramatically maintain clicks-and-mortar solutions without functional solutions. Completely synergize resource taxing relationships via premier niche markets. Professionally cultivate.</p>
              </div>
              <div class="testimonial-name">LARA ATKINSON</div>
            </div>
            <!--END OF TESTIMONIAL 3 -->
            <!--TESTIMONIAL 4 -->
            <div class="item">
              <div class="shadow-effect">
                <img class="img-circle" src="http://themes.audemedia.com/html/goodgrowth/images/testimonial3.jpg" alt="">
                <p>Dramatically maintain clicks-and-mortar solutions without functional solutions. Completely synergize resource taxing relationships via premier niche markets. Professionally cultivate.</p>
              </div>
              <div class="testimonial-name">IAN OWEN</div>
            </div>
            <!--END OF TESTIMONIAL 4 -->
            <!--TESTIMONIAL 5 -->
            <div class="item">
              <div class="shadow-effect">
                <img class="img-circle" src="http://themes.audemedia.com/html/goodgrowth/images/testimonial3.jpg" alt="">
                <p>Dramatically maintain clicks-and-mortar solutions without functional solutions. Completely synergize resource taxing relationships via premier niche markets. Professionally cultivate.</p>
              </div>
              <div class="testimonial-name">MICHAEL TEDDY</div>
            </div>
            <!--END OF TESTIMONIAL 5 -->
          </div>
        </div>
      </div>
      </div>
    </section>
    <!-- END OF TESTIMONIALS -->
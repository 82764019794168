import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-downloads',
  templateUrl: './downloads.component.html',
  styleUrls: ['./downloads.component.css']
})
export class DownloadsComponent implements OnInit {

  constructor(private api:ApiService) { }
  downloads:any
  ngOnInit(): void {
    (async () => {
      this.downloads = await this.api.getDownloads()
      this.downloads = this.downloads['data']
      this.downloads.forEach(d=> d.file = this.api.baseimgurl+d.file)
      console.log(this.downloads)
    })() 

  }

}

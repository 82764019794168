import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, } from '@angular/forms';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-knowmore',
  templateUrl: './knowmore.component.html',
  styleUrls: ['./knowmore.component.css']
})

export class KnowmoreComponent implements OnInit {

  contactform:any
  msg:any;
  msg2: any;
  formSuccess:boolean=false
  formError:any;
  ngLoader:boolean=true;
  click : boolean = false;
  contactdetails:any
  homeform: any;
  loader = false
  buttondisable = false

  constructor(private api:ApiService, private http: HttpClient) { 
    this.contactform = new FormGroup({
      name: new FormControl('', Validators.required),
      email: new FormControl('', Validators.required),
      mobile: new FormControl('', Validators.required),
      city: new FormControl('', Validators.required),
      requirement: new FormControl(''),
    });
  }
  
  ngOnInit(): void {
    
  }

  onSubmit() {
    this.buttondisable = true
    this.loader = true
    this.api.contactUs(this.contactform.value)
    .subscribe(
      val => {
        console.log(val)
        this.formSuccess=true
        this.msg=val
        this.msg=this.msg['data']['response']+' '+this.msg['data']['response2']
        this.loader = false
        this.contactform.reset()
        this.buttondisable = false
        setTimeout(
          ()=>{
            this.formSuccess=false
          },
        4000)
      },
      err=>{
        console.log(err)
        this.buttondisable = false
        this.msg=err.error.errors;
        this.loader = false
      }
    )
  }
  

}

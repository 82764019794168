<section class="contactusbanner">
    <div class="container">
        <h3>Contact Us</h3>
        <div class="row">
            <div class="col-lg-6 col-md-8 offset-lg-3 offset-md-2">
                <div class="row">
                    <div class="col-sm-6 d-flex justify-content-start">
                        <div class="cont-det d-flex">
                            <i class="fa fa-map-marker"></i>
                            <div class="cont-inner">
                                <div class="con-title">Regd. & Corporate Office</div>
                                <div class="cont-desc">8-2-293/K/5 & Plot No. 5, 3rd Floor, Kamalapuri Colony, Hyderabad- 500073</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 d-flex justify-content-sm-end">
                        <div class="cont-det d-flex">
                            <i class="fa fa-map-marker"></i>
                            <div class="cont-inner">
                                <div class="con-title">Factory</div>
                                <div class="cont-desc">Plot No-75 & 76(P), EPIP, Pashamylaram, Patancheru Mandal, Sangareddy Dist. 502307, Hyderabad, Telangana</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 d-flex justify-content-start">
                        <div class="cont-det d-flex">
                            <i class="fa fa-envelope-o"></i>
                            <div class="cont-inner">
                                <div class="con-title">Get In Touch</div>
                                <div class="cont-desc">care@nexonpaints.com</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 d-flex justify-content-sm-end">
                        <div class="cont-det d-flex">
                            <i class="fa fa-map-marker"></i>
                            <div class="cont-inner">
                                <div class="con-title">Call Us</div>
                                <div class="cont-desc">1800-120-8200</div>
                            </div>
                        </div>
                    </div>
                </div>
                <h5 class="blg-title text-center mt-4">Follow Us on Social Media</h5>
                <ul class="cont-social text-center mb-5">
                    <li><a target="_blank" href="https://www.instagram.com/nexonpaints/"><i class="fa fa-instagram"></i></a></li>
                    <li><a target="_blank" href="https://www.facebook.com/NexonPaintsVibrantIdeas/?modal=admin_todo_tour"><i class="fa fa-facebook"></i></a></li>
                    <li><a target="_blank" href="https://www.linkedin.com/company/35634203/admin"><i class="fa fa-linkedin"></i></a></li>
                    <li><a target="_blank" href="https://twitter.com/NexonPaints"><i class="fa fa-twitter"></i></a></li>
                </ul>
            </div>
        </div>
    </div>
</section>

<section class="contactus">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-6 form-left">
            </div>
            <div class="col-md-6 py-5 px-lg-4">
                <form class="contact-forms" [formGroup]="contactform" (ngSubmit)="onSubmit()" method="post" enctype="multipart/form-data">
                    <h4 class="form-title">Contact Form</h4>
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="form-group">
                                <input type="text" placeholder="Name" formControlName="name" pattern="[a-zA-Z'-'\s]*">
                                <small class="text-danger" *ngIf="!contactform.get('name').valid && contactform.get('name').touched">Please Enter Your Vaild  Name  </small>
                                <span class="small text-danger" *ngIf="msg?.name?.length>0">
                                    {{msg.name[0]}}
                                </span>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-group">
                                <input type="email" placeholder="E-mail" formControlName="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$">
                                <small class="text-danger" *ngIf="!contactform.get('email').valid && contactform.get('email').touched">Please Enter Your Vaild Email Id  </small>
                                <span class="small text-danger" *ngIf="msg?.email?.length>0">
                                    {{ msg.email[0] }}
                                </span>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-group">
                                <input 
                                    type="text" 
                                    placeholder="Phone Number" 
                                    formControlName="mobile" 
                                    (keypress)="keyPressNumbers($event)" 
                                    maxlength="10" 
                                    pattern="^[6-9]\d{9}$" >
                                    
                                <small class="text-danger" *ngIf="!contactform.get('mobile').valid && contactform.get('mobile').touched">Please Enter Your Vaild  Phone Number  </small>
                                <span class="small text-danger" *ngIf="msg?.mobile?.length>0">
                                    {{ msg.mobile[0] }}
                                </span>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-group">
                                <input type="text" placeholder="Your City" formControlName="city">
                                <small class="text-danger" *ngIf="!contactform.get('city').valid && contactform.get('city').touched">Please Enter Your City  Name  </small>
                                <span class="small text-danger" *ngIf="msg?.city?.length>0">
                                    {{msg.city[0]}}
                                </span>
                            </div>
                        </div>

                        <div class="col-lg-12">
                            <div class="form-group">
                                <textarea placeholder="Tell us your Requirements" formControlName="requirement"></textarea>
                            </div>
                        </div>
                        <div class="col-lg-12 mt-3">
                            <button type="submit" class="commmon-big-btn primary"  [disabled]="buttondisable">Send</button>
                        </div>
                        <div class="submitcenter mb-4 text-center">
                            <div class="spinner-border" *ngIf="loader"></div>
                            <div class="submitcenter mb-4" *ngIf="formSuccess">
                                <div class="col-12 mt-3 text-center">
                                    <div class="alert alert-success small">{{msg}}</div>
                                  </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

        </div>
    </div>
</section>

<section class="map">
    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15218.666212104026!2d78.1719045!3d17.5234239!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb687ecadeee98dec!2sNexon%20Paints%20Pvt.Ltd.!5e0!3m2!1sen!2sin!4v1632486436861!5m2!1sen!2sin" width="100%" height="400" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
</section>
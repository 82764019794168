import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-paint-calculator',
  templateUrl: './paint-calculator.component.html',
  styleUrls: ['./paint-calculator.component.css']
})
export class PaintCalculatorComponent implements OnInit {

  constructor(private api:ApiService) { }
  screen1: boolean = true;
  screen2: boolean = false;
  screen3: boolean = false;
  screen4: boolean = false;
  interior:boolean=true;
  exterior:boolean=false;
  showSpaceBtns:boolean=true;
  showSpaceBtns2:boolean=false;
  subText:string='Choose your paint space';
  cArea:any;
  homeLength:any;
  homeBreadth:any;
  homeHeight:any;
  doorCount:any;
  doorLength:any;
  doorBreadth:any;
  windowCount:any;
  windowLength:any;
  windowBreadth:any;
  totalArea:any;
  qtyReq:any;
  totalCost:any;
  primerCost:any;
  puttyCost:any;
  areaCovered:any;
  cost1:any
  cost2:any
  cost3:any
  suggestedProducts:any
  productImg:any
  productSlug:any
  ngOnInit(): void {
  }
  prOption: OwlOptions  = {
    items: 1,
    autoplay: false,
    loop:false,
    margin:0,
    dots: true,
    nav: false,
    responsive:{
      0:{
        items:1,
        center: true
      },
      768: {
        items: 2,
        center: false
      },
      992:{
        items:3,
        center: true
      }
    }
  }
  gotoscreen2(type: any, catId?:any){
    this.screen1=this.screen3=this.screen4=false
    this.screen2=true
    this.showSpaceBtns=false
    this.showSpaceBtns2=true
    if(type==='interior') {
      this.interior = true
      this.exterior = false
    }
    else {
      this.interior = false
      this.exterior = true
    }
    if(catId){
      (async() => {
        this.suggestedProducts = await this.api.getProductsByCategoryId(catId)
        this.suggestedProducts.map(p=>{
          p.single_image = this.api.baseimgurl+p.single_image; 
          p.thumbnail = this.api.baseimgurl+p.thumbnail
        })
        console.log(this.suggestedProducts);
      })()
    }
  }
  gotoscreen3(){
    this.screen1=this.screen2=this.screen4=false
    this.screen3=true
    this.showSpaceBtns=false
    this.showSpaceBtns2=false
    this.subText = "Choose your preferred product"
  }
  gotoscreen4(areaCov:any,thumbnail?:any,slug?:any){
    this.areaCovered = areaCov;
    this.screen1=this.screen2=this.screen3=false
    this.screen4=true
    this.showSpaceBtns=false
    this.showSpaceBtns2=false
    // this.subText = "Your customised budget estimation is ready!"
    this.subText = ""
    //Calculation
    if(this.interior){ // Check if Interior/Exterior Form is filled
      this.totalArea = (this.cArea-(this.doorCount*this.doorLength*this.doorBreadth)-(this.windowCount*this.windowLength*this.windowBreadth))*3.5
    }
    else {
      this.totalArea = (this.homeBreadth*this.homeHeight)
    }
    this.qtyReq = Math.round(this.totalArea/this.areaCovered);
    this.totalCost = this.qtyReq*994;
    this.primerCost = Math.round(this.totalArea/20)*144
    this.puttyCost = Math.round(this.totalArea/8.75)*92
    //Final Product
    this.productImg = thumbnail
    this.productSlug = slug
  }
  getProductImg(s:any){
    
  }

}

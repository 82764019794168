<section class="ideas position-relative">
    <div class="extra-float"><div class="container px-5"><div class="float-inner col-12"></div></div></div>
    <div class="container position-relative pt-5">
        <div class="about-title-wrap">
            <div class="about-title text-center px-5">
                <h2>Certifications</h2>
            </div>
        </div>
    </div>
    <div class="container mt-5">
        <div class="row align-items-center">
            <div class="col-lg-2 px-0 text-center text-lg-right">
                <ul class="eco-count left" id="eco-count" >
                    <li class="countchage active" data-target="bkt1">1</li>
                    <li class="countchage" data-target="bkt2">2</li>
                    <li class="countchage" data-target="bkt3">3</li>
                </ul>
            </div>
            <div class="col-lg-10">
                <div class="row flex-lg-row-reverse align-items-center">
                    <div class="col-lg-3 px-0 text-center text-lg-left">
                        <ul class="eco-count right" id="eco-count">
                            <li class="countchage" data-target="bkt4">4</li>
                            <li class="countchage" data-target="bkt5">5</li>
                            <li class="countchage" data-target="bkt6">6</li>
                            <li class="countchage" data-target="bkt7">7</li>
                        </ul>
                    </div>
                    <div class="col-lg-9 px-0" id="Eco-Friendly-img">
                        <img id="bkt1" class="main-eco full changeimgeco" src="assets/images/certifications/cert1.png" alt="" >
                        <img id="bkt2" class="main-eco full changeimgeco hidden" src="assets/images/certifications/cert2.png" alt="" >
                        <img id="bkt3" class="main-eco full changeimgeco hidden" src="assets/images/certifications/cert3.png" alt="" >
                        <img id="bkt4" class="main-eco full changeimgeco hidden" src="assets/images/certifications/cert4.png" alt="" >
                        <img id="bkt5" class="main-eco full changeimgeco hidden" src="assets/images/certifications/cert5.png" alt="" >
                        <img id="bkt6" class="main-eco full changeimgeco hidden" src="assets/images/certifications/cert6.png" alt="" >
                        <img id="bkt7" class="main-eco full changeimgeco hidden" src="assets/images/certifications/cert7.png" alt="" >
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
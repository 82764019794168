import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-colors',
  templateUrl: './colors.component.html',
  styleUrls: ['./colors.component.css']
})
export class ColorsComponent implements OnInit {
  shadeForm:any;
  formSuccess:boolean = false;
  msg:any;
  constructor(private api:ApiService) {
    this.shadeForm = new FormGroup({
      'name': new FormControl('', Validators.required),
      'email': new FormControl('', [Validators.required, Validators.email]),
      'mobile': new FormControl('', Validators.required),
      'city' : new FormControl('', Validators.required),
      'shade' : new FormControl(''),
      'pincode' : new FormControl('', Validators.required)
    })
  }
  colors = [
    {
      varientId:1,
      varientName:'Violet',
      varientCode:'#9400D3',
      varientShades:[
        '#E6E6FA',
        '#D8BFD8',
        '#DDA0DD',
        '#DA70D6',
        '#EE82EE',
        '#FF00FF',
        '#FF00FF',
        '#BA55D3',
        '#9932CC',
        '#9400D3',
        '#8A2BE2',
        '#8B008B',
        '#800080',
        '#9370DB',
        '#7B68EE',
        '#6A5ACD',
        '#483D8B',
        '#663399',
        '#4B0082',
      ]
    },
    {
      varientId:2,
      varientName:'Indigo',
      varientCode:'#4B0082',
      varientShades:[
            '#07000c',
            '#120020',
            '#1e0034',
            '#290047',
            '#34005b',
            '#40006e',
            '#4b0082',
            '#560096',
            '#6200a9',
            '#6d00bd',
            '#7800d0',
            '#8400e4',
            '#8f00f8',
            '#980cff',
            '#a120ff',
            '#a934ff',
            '#b147ff',
            '#ba5bff',
            '#c26eff',
            '#ca82ff',
            '#d296ff',
            '#dba9ff',
            '#e3bdff',
            '#ebd0ff',
            '#f4e4ff',
            '#fcf8ff',
        
      ]
    },
    {
      varientId:3,
      varientName:'Blue',
      varientCode:'#0000FF',
      varientShades:[
        '#5F9EA0',
        '#4682B4',
        '#B0C4DE',
        '#ADD8E6',
        '#B0E0E6',
        '#87CEFA',
        '#87CEEB',
        '#6495ED',
        '#00BFFF',
        '#1E90FF',
        '#4169E1',
        '#0000FF',
        '#0000CD',
        '#00008B',
        '#000080',
        '#191970',
      ]
    },
    {
      varientId:4,
      varientName:'Green',
      varientCode:'#00FF00',
      varientShades:[
        '#ADFF2F',
        '#7FFF00',
        '#7CFC00',
        '#00FF00',
        '#32CD32',
        '#98FB98',
        '#90EE90',
        '#00FA9A',
        '#00FF7F',
        '#3CB371',
        '#2E8B57',
        '#228B22',
        '#008000',
        '#006400',
        '#9ACD32',
        '#6B8E23',
        '#556B2F',
        '#66CDAA',
        '#8FBC8F',
        '#20B2AA',
        '#008B8B',
        '#008080',
      ]
    },
    {
      varientId:5,
      varientName:'Yellow',
      varientCode:'#FFFF00',
      varientShades:[
            '#141400',
            '#272700',
            '#3b3b00',
            '#4e4e00',
            '#626200',
            '#767600',
            '#898900',
            '#9d9d00',
            '#b1b100',
            '#c4c400',
            '#d8d800',
            '#ebeb00',
            '#ffff00',
            '#ffff14',
            '#ffff27',
            '#ffff3b',
            '#ffff4e',
            '#ffff62',
            '#ffff76',
            '#ffff89',
            '#ffff9d',
            '#ffffb1',
            '#ffffc4',
            '#ffffd8',
            '#ffffeb',
            '#ffffff',
      ]
    },
    {
      varientId:6,
      varientName:'Orange',
      varientCode:'#FF7F00',
      varientShades:[
            '#140a00',
            '#271400',
            '#3b1d00',
            '#4e2700',
            '#623100',
            '#763b00',
            '#894400',
            '#9d4e00',
            '#b15800',
            '#c46200',
            '#d86b00',
            '#eb7500',
            '#ff7f00',
            '#ff8914',
            '#ff9327',
            '#ff9d3b',
            '#ffa64e',
            '#ffb062',
            '#ffba76',
            '#ffc489',
            '#ffce9d',
            '#ffd8b1',
            '#ffe1c4',
            '#ffebd8',
            '#fff5eb',
            '#ffffff',
      ]
    },
    {
      varientId:7,
      varientName:'Red',
      varientCode:'#FF0000',
      varientShades:[
            '#140000',
            '#270000',
            '#3b0000',
            '#4e0000',
            '#620000',
            '#760000',
            '#890000',
            '#9d0000',
            '#b10000',
            '#c40000',
            '#d80000',
            '#eb0000',
            '#ff0000',
            '#ff1414',
            '#ff2727',
            '#ff3b3b',
            '#ff4e4e',
            '#ff6262',
            '#ff7676',
            '#ff8989',
            '#ff9d9d',
            '#ffb1b1',
            '#ffc4c4',
            '#ffd8d8',
            '#ffebeb',
            '#ffffff',
      ]
    }
  
  ]
  currentCode: any = '#ff0000';
  shades:any;
  ngOnInit(): void {
    this.shades = this.colors[0].varientShades
    console.log(this.shades);
  }

  getShades(id:any) {
    this.shades = this.colors[id].varientShades
  }
  openPopup(code:any){
    this.currentCode = code;
    document.querySelector('.color-popup').classList.add('active');
  }
  closePopup(){
    document.querySelector('.color-popup').classList.remove('active');
  }

  submitShadeForm(){
    this.shadeForm.value.shade = this.currentCode
    console.log(this.shadeForm.value)
    this.api.shadeEnquiry(this.shadeForm.value).subscribe(data=> {
      console.log(data);
      this.msg = data;
      this.msg = this.msg.msg;
      this.formSuccess = true;
      // (async ()=>{
      //   this.commentList = await this.api.getCommentList(this.blogId);
      //   this.commentList = this.commentList['data']
      // })()
      setTimeout(()=>{
        this.formSuccess = false
        this.shadeForm.reset();
        document.querySelector('.color-popup').classList.remove('active');
      }, 3000)
    })
  }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Url } from 'url';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {
  constructor(private api:ApiService) { }

  getproducts:any;

  async ngOnInit() {
    this.getproducts = await this.api.Getproducts()
    this.getproducts.forEach((s:any)=>s.thumbnail=this.api.baseimgurl+s.thumbnail)
    this.getproducts.forEach((p:any)=>p.p_image=this.api.baseimgurl+p.p_image)
  }

  
}

<div class="container">
    <ul class="blog-breadcrumb">
        <li><a routerLink="/">Home</a></li>
        <li><a routerLink="/blogs">Blogs</a></li>
        <li>{{blogTitle}}</li>
    </ul>
</div>
<div class="blog-main">
    <div class="container mb-5">
        <div class="row">
            <ng-container *ngFor="let blg of blogDetails">
                <div class="col-lg-12 col-md-12">
                    <div class="blog-grid">
                        <img [src]="blg?.image"  alt="Blog 1" class="full">
                        <div class="blg-cap d-flex justify-content-between">
                            <span class="d-flex align-items-center"><i class="fa fa-thumbs-o-up mr-2"></i>{{blg.likes_count}} Likes</span>
                            <span class="d-flex align-items-center"><i class="fa fa-comment-o mr-2"></i>15 Comments</span>
                        </div>
                        <!-- <ul class="blg-tags"><li>Interior Design</li><li>Lorem Ipsum</li></ul> -->
                        <h4 class="blog-title my-4">{{blg.heading}}</h4>
                        <div class="blog-meta">
                            <span class="mr-3"><i class="fa fa-user-o mr-2"></i>{{blg.author}}</span>
                            <span><i class="fa fa-calendar-o mr-2"></i>{{blg.Date}}</span>
                        </div>
                        <div class="blog-body mt-4">
                        <div [innerHTML]="blg.text"></div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <!-- <div class="col-lg-3 col-md-4">
                <div class="blg-sidebar">
                    <div class="side-title d-flex justify-content-between">
                        <span>Filters</span>
                        <span>Clear All</span>
                    </div>
                    <select name="" id="" class="form-control filter-control">
                        <option value="Latest" selected>Latest</option>
                        <option value="Oldest">Oldest</option>
                    </select>
                    <select name="" id="" class="form-control filter-control">
                        <option value="Latest" selected>Category</option>
                        <option value="Oldest">Oldest</option>
                    </select>
                    <select name="" id="" class="form-control filter-control">
                        <option value="Latest" selected>Area</option>
                        <option value="Oldest">Oldest</option>
                    </select>
                    <select name="" id="" class="form-control filter-control">
                        <option value="Latest" selected>Archieves</option>
                        <option value="Oldest">Oldest</option>
                    </select>
                    <div class="side-title d-flex justify-content-between mt-3">
                        <span>Tags</span>
                    </div>
                    <ul class="sidetags">
                        <li>Lorem Ipsum</li>
                        <li>Lorem Ipsum</li>
                        <li>Lorem Ipsum</li>
                        <li>Lorem Ipsum</li>
                        <li>Lorem Ipsum</li>
                        <li>Lorem Ipsum</li>
                        <li>Lorem Ipsum</li>
                        <li>Lorem Ipsum</li>
                        <li>Lorem Ipsum</li>
                        <li>Lorem Ipsum</li>
                        <li>Lorem Ipsum</li>
                        <li>Lorem Ipsum</li>
                        <li>Lorem Ipsum</li>
                        <li>Lorem Ipsum</li>
                        <li>Lorem Ipsum</li>
                        <li>Lorem Ipsum</li>
                        <li>Lorem Ipsum</li>
                        <li>Lorem Ipsum</li>
                        <li>Lorem Ipsum</li>
                        <li>Lorem Ipsum</li>
                    </ul>
                </div>
            </div> -->
        </div>
    </div>
    <div class="blog-comments">
        <div class="container">
            <h5><strong>{{commentList?.length}} Comments</strong></h5>
        </div>
        <div class="comment-wrap mt-4">
            <div class="container">
                <ng-container *ngFor="let c of commentList">
                    <div class="comment-single">
                        <div class="row">
                            <div class="col-lg-6 col-md-8 d-flex align-items-start">
                                <img src="assets/images/person.png" alt="" class="mr-3">
                                <div class="comment-body">
                                    <div class="user">{{c.name}}</div>
                                    <div class="date">{{c.created_at | date}}</div>
                                    <p class="cbody">{{c.comment}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="reply-main mb-5">
        <h4 class="reply-title">Leave a Reply</h4>
        <div class="reply-form">
            <div class="container">
                <div class="row">
                    <form [formGroup]="commentform" (ngSubmit)="onSubmit()">
                        <div class="col-lg-6 offset-lg-1">
                            <div class="row">
                                <div class="col-md-12">
                                    <!-- <input type="hidden" formControlName="blog_id"> -->
                                    <div class="form-group">
                                        <textarea class="form-control" formControlName="comment" placeholder="Your Comment"></textarea>
                                        <small class="text-danger" *ngIf="!commentform.get('comment').valid && commentform.get('comment').touched">Please Enter Your Comment </small>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input type="text" class="form-control"  formControlName="name" placeholder="Name">
                                        <small class="text-danger" *ngIf="!commentform.get('name').valid && commentform.get('name').touched">Please Enter Your Name  </small>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input type="email" class="form-control"  formControlName="email" placeholder="E-mail Id">
                                        <small class="text-danger" *ngIf="!commentform.get('email').valid && commentform.get('email').touched">Please Enter Vaild Email</small>
                                    </div>
                                </div>
                                <div class="col-md-12 mt-4">
                                    <button class="commmon-big-btn primary" [disabled]="!commentform.valid">Submit</button>
                                </div>
                                <div class="col-md-12">
                                    <div *ngIf="formSuccess" class="mt-4">
                                        <span class="alert alert-success">{{msg}}</span>
                                    </div>
                                    <div *ngIf="formError" class="mt-4">
                                        <span class="alert alert-danger">{{msg}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                  </form>
                  
                    
                </div>
            </div>
        </div>
    </div>
</div>
<app-knowmore></app-knowmore>
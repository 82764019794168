import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';

@Component({
  selector: 'app-subcategory',
  templateUrl: './subcategory.component.html',
  styleUrls: ['./subcategory.component.css']
})
export class SubcategoryComponent implements OnInit {
  
  Category: any;
  firstid: any;
  sub:any;
  categoryTitle:any;
  isSubcategory:boolean=false;
  constructor(private api: ApiService, private route: ActivatedRoute, private router:Router) {
    this.router.events.subscribe((event:RouterEvent) => {
      if(event instanceof NavigationEnd) {
        this.sub = this.route.params.subscribe(params => {
          this.firstid = params['catslug'];
          this.getCategory();
        });
      }
    });
  }
  ngOnInit():void {
    
  }

  async getCategory() {
    this.Category = await this.api.getProductsByCategoryId(this.firstid);
    this.Category = this.Category.data;
    if(this.Category[0]==undefined){
      this.categoryTitle=this.Category['category'];
      this.isSubcategory=true;
      this.Category=Object.values(this.Category)
      this.Category?.forEach(c=>{
        c.products?.forEach(p=>{
          p.p_image = this.api.baseimgurl+p.p_image;
          p.thumbnail = this.api.baseimgurl+p.thumbnail;
        })
      })
    }
    else{
      this.categoryTitle=this.Category[0]['category']['category'];
      this.isSubcategory=false
      this.Category.forEach((c:any)=>{
        c.p_image = this.api.baseimgurl+c.p_image;
        c.thumbnail = this.api.baseimgurl+c.thumbnail;
      })
    }
  }



}

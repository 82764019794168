<section class="product-range position-relative pt-3 pb-4">
    <div class="extra-float"><div class="container px-5"><div class="float-inner col-12"></div></div></div>
    <div class="container position-relative">
        <div class="about-title-wrap justify-content-between align-items-center">
            <div class="about-title">
                <h2>Downloads</h2>
            </div>
            <img src="assets/images/home/bucket-model.png" alt="Spilled Bucket" class="about-title-img">
        </div>
    </div>    
    <div class="container mt-4">
        <div class="row">
            <div class="col-lg-10">
                <div class="faq-wrap">
                    <ng-container *ngFor="let d of downloads">
                        <div class="faq-single">
                            <div class="fhead">
                                {{d.name}}
                                <a target="_blank" [href]="d.file" class="f-trigger" download=""></a>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</section>
<app-knowmore></app-knowmore>
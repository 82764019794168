<section class="calculator-head pt-5 pb-4 mb-2 text-center">
    <div class="container">
        <div class="row">
            <div class="col-xl-6 col-md-12 offset-xl-3">
                <h1 class="calculator-title mb-3">Paint Calculator</h1>
                <p class="calc-desc class mb-5">Paint Calculator helps you calculate the area to be painted and gives you an estimate of the required amount of paint.</p>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-10 offset-xl-1">
                <ul class="calc-menu">
                    <li [ngClass]="{'active': screen1||screen2||screen3||screen4}"><span>CHOOSE SPACE</span></li>
                    <li [ngClass]="{'active': screen2||screen3||screen4}"><span>SURFACE DIMENSION</span></li>
                    <li [ngClass]="{'active': screen3||screen4}"><span>SELECT PRODUCT & ESTIMATION</span></li>
                    <li [ngClass]="{'active': screen4}"><span>FINAL RESULT</span></li>
                </ul>
                <ng-container  *ngIf="subText!=''">
                    <p class="paint-choose">{{subText}}</p>
                    <hr>
                </ng-container>
                <div class="space-buttons mt-5" *ngIf="showSpaceBtns">
                    <button type="button" (click)="gotoscreen2('interior', 10)">INTERIOR</button>
                    <button type="button" (click)="gotoscreen2('exterior', 11)">EXTERIOR</button>
                </div>
                <div class="space-buttons mt-5" *ngIf="showSpaceBtns2">
                    <button [ngClass]="{'active': interior}" type="button" (click)="gotoscreen2('interior', 10)">INTERIOR</button>
                    <button [ngClass]="{'active': exterior}" type="button" (click)="gotoscreen2('exterior', 11)">EXTERIOR</button>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="calculator-body pb-5" *ngIf="screen1">
    <div class="container">
        <div class="row">
            <div class="col-md-6 mb-4 mb-md-0 cursor-pointer" (click)="gotoscreen2('interior', 10)">
                <img src="assets/images/calculator/interior.jpg" class="full" alt="">
            </div>
            <div class="col-md-6 cursor-pointer" (click)="gotoscreen2('exterior', 11)">
                <img src="assets/images/calculator/exterior.jpg" class="full" alt="">
            </div>
        </div>
    </div>
</section>

<section class="int-dim" *ngIf="screen2">
    <div class="container pb-5">
        <div class="row">
            <div class="col-xl-10 offset-xl-1" *ngIf="interior">
                <div class="row align-items-center text-center mb-4">
                    <div class="col-lg-6 d-flex justify-content-start">
                        <div class="int-inner">
                            <div class="cal-t text-center mb-4">Paint type</div>
                            <div class="row">
                                <div class="col-6">
                                    <div class="int-radio">
                                        <input type="radio" name="ptype" id="ptype1" checked>
                                        <label for="ptype1">Fresh Paint</label>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="int-radio">
                                        <input type="radio" name="ptype" id="ptype2">
                                        <label for="ptype2">Re-Paint</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 d-flex justify-content-end">
                        <div class="int-inner">
                            <div class="cal-t text-center mb-4">Measurement Unit</div>
                            <div class="row">
                                <div class="col-6">
                                    <div class="int-radio">
                                        <input type="radio" name="mtype" id="mtype1" checked>
                                        <label for="mtype1">Square Feet</label>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="int-radio">
                                        <input type="radio" name="mtype" id="mtype2">
                                        <label for="mtype2">Square Metres</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="shadow-box p-4 mb-4">
                    <div class="row align-items-center">
                        <div class="col-lg-4">
                            <div class="shadow-title">Total Carpet Area</div>
                            <div class="shadow-desc">(Dimensions of the surface to be printed)</div>
                        </div>
                        <div class="col-lg-5">
                            <div class="shadow-desc ml-4 mb-1">Total Area</div>
                            <input type="number" class="shadow-input" [(ngModel)]="cArea" placeholder="Square Feet">
                        </div>
                        <div class="col-lg-3 text-center text-lg-right">
                            <img src="assets/images/calculator/square.png" alt="Square" class="img-fluid">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6">
                        <div class="shadow-box p-4 mb-4">
                            <div class="shadow-title mb-3">No. of Door(s)</div>
                            <div class="row align-items-end">
                                <div class="col-lg-4">
                                    <input type="number" class="shadow-input" [(ngModel)]="doorCount" placeholder="1">
                                </div>
                                <div class="col-lg-4">
                                    <div class="shadow-desc text-center mb-1">Length</div>
                                    <input type="number" class="shadow-input" [(ngModel)]="doorLength" placeholder="Feet">
                                </div>
                                <div class="col-lg-4 text-center text-lg-right">
                                    <div class="shadow-desc text-center mb-1">Breadth</div>
                                    <input type="number" class="shadow-input" [(ngModel)]="doorBreadth" placeholder="Feet">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="shadow-box p-4 mb-4">
                            <div class="shadow-title mb-3">No. of Window(s)</div>
                            <div class="row align-items-end">
                                <div class="col-lg-4">
                                    <input type="number" class="shadow-input" [(ngModel)]="windowCount" placeholder="1">
                                </div>
                                <div class="col-lg-4">
                                    <div class="shadow-desc text-center mb-1">Length</div>
                                    <input type="number" class="shadow-input" [(ngModel)]="windowLength" placeholder="Feet">
                                </div>
                                <div class="col-lg-4 text-center text-lg-right">
                                    <div class="shadow-desc text-center mb-1">Breadth</div>
                                    <input type="number" class="shadow-input" [(ngModel)]="windowBreadth" placeholder="Feet">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-10 offset-xl-1" *ngIf="exterior">
                <!-- <div class="shadow-box p-4 mb-4">
                    <div class="row align-items-center">
                        <div class="col-lg-4">
                            <div class="shadow-title">Length</div>
                            <div class="shadow-desc">(Dimensions of the surface to be printed)</div>
                        </div>
                        <div class="col-lg-5">
                            <input type="number" class="shadow-input" [(ngModel)]="homeLength" placeholder="0 Feet">
                        </div>
                        <div class="col-lg-3 text-center text-lg-right">
                            <img src="assets/images/calculator/ex-length.png" alt="Length" class="img-fluid">
                        </div>
                    </div>
                </div> -->
                <div class="shadow-box p-4 mb-4">
                    <div class="row align-items-center">
                        <div class="col-lg-4">
                            <div class="shadow-title">Breadth</div>
                        </div>
                        <div class="col-lg-5">
                            <input type="number" class="shadow-input" [(ngModel)]="homeBreadth" placeholder="0 Feet">
                        </div>
                        <div class="col-lg-3 text-center text-lg-right">
                            <img src="assets/images/calculator/ex-breadth.png" alt="Breadth" class="img-fluid">
                        </div>
                    </div>
                </div>
                <div class="shadow-box p-4 mb-4">
                    <div class="row align-items-center">
                        <div class="col-lg-4">
                            <div class="shadow-title">Height</div>
                        </div>
                        <div class="col-lg-5">
                            <input type="number" class="shadow-input" [(ngModel)]="homeHeight" placeholder="0 Feet">
                        </div>
                        <div class="col-lg-3 text-center text-lg-right">
                            <img src="assets/images/calculator/ex-height.png" alt="Height" class="img-fluid">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-center">
            <div class="space-buttons mt-4">
                <button type="button" (click)="gotoscreen3()">Next</button>
            </div>
        </div>
    </div>
</section>

<section class="calculator-body pb-5" *ngIf="screen3">
    <div class="container">
        <div class="row">
            <div class="col-xl-10 offset-xl-1">
                <!-- <div class="row text-center">
                    <owl-carousel-o [options]="prOption">
                        <ng-container *ngFor="let slide of suggestedProducts; let i=index">
                            <ng-template carouselSlide id="slide{{i}}">
                                <img [src]="slide.single_image" [alt]="slide.name" class="img-fluid mb-4">
                                <button class="prod-btn" (click)="gotoscreen4(slide.price)">Select Product</button>
                            </ng-template>
                          </ng-container>
                    </owl-carousel-o>
                </div> -->
                <div class="row justify-content-center text-center">
                    <ng-container *ngFor="let slide of suggestedProducts; let i=index">
                        <div class="col-md-4 mb-4">
                            <img [src]="slide.single_image" [alt]="slide.name" class="img-fluid mb-4">
                            <button class="prod-btn" (click)="gotoscreen4(slide.price, slide.thumbnail, slide.slug)">Select Product</button>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="text-center">
            <div class="space-buttons mt-5">
                <button type="button" (click)="gotoscreen2('interior')">Back</button>
            </div>
        </div>
    </div>
</section>

<section class="calculator-result-final mb-5"  *ngIf="screen4">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 mb-4 mb-lg-0 prod-showcase">
                <!-- <img src="assets/images/calculator/prod3-group.png" alt="" class="full"> -->
                <img [src]="productImg" alt="" class="full">
                <a routerLink="/product/{{productSlug}}" class="prod-btn">View Product</a>
            </div>
            <div class="col-lg-6">
                <div class="calc-final px-4 px-lg-5 py-5">
                    <div class="table-responsive">
                        <table class="zig-table">
                            <tr>
                                <td>Total Area :</td>
                                <td>{{totalArea}} sq.ft.</td>
                            </tr>
                            <tr>
                                <td>Coverage Area :</td>
                                <td>{{areaCovered}} sq. ft. / Ltr</td>
                            </tr>
                            <tr>
                                <td>Quantity Required :</td>
                                <td>{{qtyReq}} Litres</td>
                            </tr>
                            <tr>
                                <td colspan="2">
                                    <hr class="border-white">
                                </td>
                            </tr>
                            <tr>
                                <td>Paint Cost :</td>
                                <td>Rs {{totalCost}}</td>
                            </tr>
                            <tr>
                                <td>Primer Cost :</td>
                                <td>Rs {{primerCost}}</td>
                            </tr>
                            <tr>
                                <td>Putty cost :</td>
                                <td>Rs {{puttyCost}}</td>
                            </tr>
                            <tr class="border-top border-white">
                                <td>Total Estimated Cost :</td>
                                <td>Rs {{totalCost+primerCost+puttyCost}}</td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div class="d-flex justify-content-end mt-2">
                    <span class="btns mr-4 d-flex align-items-center cursor-pointer"><i class="fa fa-download mr-2"></i>Download</span>
                    <span class="btns d-flex align-items-center cursor-pointer"><i class="fa fa-share-alt mr-2"></i>Share</span>
                </div>
            </div>
        </div>
        <div class="text-center">
            <div class="space-buttons mt-5">
                <button type="button" (click)="gotoscreen3()">Back</button>
            </div>
        </div>
    </div>
</section>
<div class="container text-center pb-5">
    <div style="color: red;"><strong>Note:</strong> Labour cost is extra and based on the skill and experience. It may vary from city-to-city and is not included.</div>
</div>
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../api.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-blog-single',
  templateUrl: './blog-single.component.html',
  styleUrls: ['./blog-single.component.css']
})
export class BlogSingleComponent implements OnInit {
  Comment: any;
  blogsingle: any;
  firstid: any;
  sub:any;
  Getbloglist: any;
  commentform: any;
  status:any;
  msg:any;
  formSuccess:any;
  formError:any;
  blogList:any;
  blogTitle:any;
  blogId:any;
  urlSlug:any;
  blogDetails:any;
  commentList:any;
  constructor(private api:ApiService, private route:ActivatedRoute) {
    this.route.params.subscribe(p=>{
      this.urlSlug = p.slug
    })
    this.commentform = new FormGroup({
      'comment': new FormControl('', Validators.required),
      'email': new FormControl('', [Validators.required, Validators.email]),
      'name': new FormControl('', Validators.required),
      'blog_id' : new FormControl('')
    });
  }
  async ngOnInit() {
    this.blogDetails = await this.api.getBlogDetails(this.urlSlug)
    this.blogDetails=this.blogDetails['response']
    this.blogDetails.forEach((s:any)=>s.image=this.api.baseimgurl+s.image)
    this.blogId = this.blogDetails[0]['id']
    this.commentList = await this.api.getCommentList(this.blogId);
    this.commentList = this.commentList['data']
    this.blogList = await this.api.getBlogs()
    this.blogList=this.blogList['response']
    this.blogList.forEach((s:any)=>s.image=this.api.baseimgurl+s.image)
    this.blogTitle = this.blogDetails[0]['heading'];

  }

  onSubmit(){
    this.commentform.value.blog_id = this.blogId
    console.log(this.commentform.value)
    this.api.CommentApi(this.commentform.value).subscribe(data=> {
      console.log(data);
      this.msg = data;
      this.msg = this.msg.msg;
      this.formSuccess = true;
      // (async ()=>{
      //   this.commentList = await this.api.getCommentList(this.blogId);
      //   this.commentList = this.commentList['data']
      // })()
      setTimeout(()=>{
        this.formSuccess = false
        this.commentform.reset();
      }, 3000)
    })
  }

}



import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-branch-locator',
  templateUrl: './branch-locator.component.html',
  styleUrls: ['./branch-locator.component.css']
})
export class BranchLocatorComponent implements OnInit {

  constructor(private api:ApiService) { }
  stateList:any
  cityList:any;
  currentState:any;
  currentCity:any;
  branches=[]
  branchRes:any
  loading:boolean = false;
  searched:boolean=true
  async ngOnInit() {
    this.stateList = await this.api.getStateList()
    this.stateList = this.stateList['data'];
    console.log(typeof this.branches)
  }
  getCities(id:any) {
    this.loading = true
    this.currentState = id;
    (async()=>{
      this.cityList = await this.api.getCityFromState(id);
      this.loading = false
      this.cityList = this.cityList['data'];
    })()
    
  }
  getBranches(cityId:any) {
    this.loading = true
    this.currentCity = cityId;
    (async()=>{
      this.branchRes = await this.api.getBranchesFromStateCity(this.currentState, this.currentCity)
      this.loading = false
      this.branches = this.branchRes['data'];
      this.searched=false
    })()
  }

}

<section class="join-us pt-0 py-md-5">
    <div class="container-fluid pt-0 pt-md-4 pb-5">
        <div class="row flex-md-row-reverse">
            <div class="col-md-6 pad0 mb-4 mb-md-0">
                <owl-carousel-o [options]="colorOption">
                    <ng-template carouselSlide>
                        <img src="assets/images/joinus/slide.jpg">
                    </ng-template>
                    <ng-template carouselSlide>
                        <img src="assets/images/joinus/slide1.jpg">
                    </ng-template>
                    <!-- <ng-template carouselSlide>
                        <img src="assets/images/joinus/slide.jpg">
                    </ng-template>
                    <ng-template carouselSlide>
                        <img src="assets/images/joinus/slide.jpg">
                    </ng-template> -->
                </owl-carousel-o>
            </div>
            <div class="col-md-5 offset-md-1 pl-md-0">
                <h1 class="j1-title">Join Us</h1>
                <h4 class="j1-subtitle">Why Nexon</h4>
                <div class="mainp">At Nexon, we don’t just stress on maintaining superior quality in our products but also in our work culture. That’s why we welcome fresh ideas, new approach and talent with open arms. As dynamic people create an encouraging and stimulating work environment that propels the company to constantly achieve greater heights and set new benchmarks. And if this philosophy interests you, apply for the open positions mentioned below.</div>
            </div>
        </div>
    </div>
</section>

<section class="job-positions">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-6 mb-5 mb-md-0 p-0 pt-4 position-list">
                <div class="about-title-wrap d-flex justify-content-between align-items-end">
                    <img src="assets/images/home/bucket-model3.png" alt="Spilled Bucket" class="about-title-img">
                    <div class="about-title">
                        <h2>Open</h2>
                        <p>Positions</p>
                    </div>
                </div>
                <div class="positions-wrap mt-0 mt-lg-5">
                    <div *ngFor="let positions of careerPositions; let i = index;" class="position-single d-flex align-items-center justify-content-between">
                        <div class="position-detail">
                            <div class="post">{{ positions.position_name }}</div>
                            <div class="post-location"><i class="fa fa-map-marker mr-2"></i>{{ positions.job_location }}</div>
                        </div>
                        <button class="position-btn" (click)="setPosition( positions.position_name )">Apply Now</button>
                    </div>
                </div>
            </div>
            <div class="col-md-6 pb-3 px-4">
                <form [formGroup]="careerForm" (ngSubmit)="onSubmit()">
                    <div class="career-form pt-md-5 mt-md-5 pb-4 pb-md-0">
                        <h4 class="career-heading"><strong>Selected Position: </strong>&nbsp;{{position}}</h4>
                        <input 
                            type="hidden" 
                            [formControl]="careerForm.controls['position']"
                            class="form-control position-control" 
                            placeholder="Name">
                        <div class="form-group mb-4">
                            <input 
                                type="text" 
                                [formControl]="careerForm.controls['name']"
                                class="form-control position-control" 
                                placeholder="Name">
                            <small class="text-danger" *ngIf="!careerForm.get('name').valid && careerForm.get('name').touched">Please Enter Your Valid  Name  </small>
                            <span class="small text-danger" *ngIf="msg?.name?.length>0">
                                {{msg.name[0]}}
                            </span>
                        </div>
                        <div class="form-group mb-4">
                            <input 
                                type="email" 
                                [formControl]="careerForm.controls['email']"
                                class="form-control position-control" 
                                placeholder="example@abc.com">
                            <small class="text-danger" *ngIf="!careerForm.get('email').valid && careerForm.get('email').touched">Please Enter Your Valid Email  </small>
                            <span class="small text-danger" *ngIf="msg?.email?.length>0">
                                {{msg.email[0]}}
                            </span>
                        </div>
                        <div class="form-group mb-4">
                            <input 
                                type="number" 
                                [formControl]="careerForm.controls['phone']"
                                class="form-control position-control" 
                                placeholder="0123456789">
                            <small class="text-danger" *ngIf="!careerForm.get('phone').valid && careerForm.get('phone').touched">Please Enter Your Valid Phone  </small>
                            <span class="small text-danger" *ngIf="msg?.phone?.length>0">
                                {{msg.phone[0]}}
                            </span>
                        </div>
                        <div class="form-group mb-4">
                            <label>Upload CV</label>
                            <input 
                                type="file" 
                                [formControl]="careerForm.controls['file']"
                                class="form-control position-control" 
                                (change)="onChange($event)"
                                >
                            <small class="text-danger" *ngIf="!careerForm.get('file').valid && careerForm.get('file').touched">Please upload your CV  </small>
                            <span class="small text-danger" *ngIf="msg?.file?.length>0">
                                {{msg.file[0]}}
                            </span>
                            <span class="small">File Format: pdf, txt</span>
                        </div>
                        <div>
                            <button type="submit" class="commmon-big-btn primary"  [disabled]="buttondisable">Submit</button>
                        </div>
                        <div class="submitcenter mb-4 mt-4 text-center">
                            <div class="spinner-border" *ngIf="loader"></div>
                            <div class="submitcenter mb-4" *ngIf="formSuccess">
                                <div class="col-12 mt-3 text-center">
                                    <div class="alert alert-success small">{{msg}}</div>
                                  </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>
<app-knowmore></app-knowmore>
<section class="know-more">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-md-4 d-none d-md-block p-0">
                <img src="assets/images/home/know-more-img.png" alt="Know More" class="full">
            </div>
            <div class="col-md-8 py-5">
                <h3 class="know-title">Know more about us</h3>
                <p class="know-desc">Fill the below details to get a free consultation by Nexon Paints.</p>
                <div class="know-form">

                <form [formGroup]="contactform" (ngSubmit)="onSubmit()">
                    <div class="row">
                        <div class="col-sm-6">
                            <input 
                                type="text" 
                                formControlName="name"  
                                class="know-input" 
                                placeholder="Name*">
                            <small class="text-danger" *ngIf="!contactform.get('name').valid && contactform.get('name').touched">Name is required </small>
                            <span class="small text-danger" *ngIf="msg?.name?.length>0">
                                {{msg.name[0]}}
                            </span>
                        </div>
                        <div class="col-sm-6">
                            <input 
                                type="email" 
                                formControlName="email"  
                                class="know-input" 
                                placeholder="Email*">
                            <small class="text-danger" *ngIf="!contactform.get('email').valid && contactform.get('email').touched">Email Id is required & should be valid  </small>
                            <span class="small text-danger" *ngIf="msg?.email?.length>0">
                                {{ msg.email[0] }}
                            </span>
                        </div>
                        <div class="col-sm-6">
                            <input 
                                type="number" 
                                formControlName="mobile"  
                                class="know-input" 
                                placeholder="Mobile No.*">
                            <small class="text-danger" *ngIf="!contactform.get('mobile').valid && contactform.get('mobile').touched">Phone Number is required & should be valid </small>
                            <span class="small text-danger" *ngIf="msg?.mobile?.length>0">
                                {{ msg.mobile[0] }}
                            </span>
                        </div>
                        <div class="col-sm-6">
                            <input 
                                type="text" 
                                formControlName="city"  
                                class="know-input" 
                                placeholder="City*">
                            <small class="text-danger" *ngIf="!contactform.get('city').valid && contactform.get('city').touched">City is required </small>
                            <span class="small text-danger" *ngIf="msg?.city?.length>0">
                                {{msg.city[0]}}
                            </span>
                        </div>
                        <div class="col-sm-12">
                            <input 
                                type="text"  
                                formControlName="requirement"  
                                class="know-input" 
                                placeholder="Message*">
                            <small class="text-danger" *ngIf="!contactform.get('requirement').valid && contactform.get('requirement').touched">Please Enter Your Message</small>
                        </div>
                        <div class="col-12 text-left">
                            <button type="submit" value="submit" class="commmon-big-btn primary">Submit</button>
                        </div>
                        <div class="col-12 text-left">
                            <div class="submitcenter mb-4 text-center">
                                <div class="spinner-border" *ngIf="loader"></div>
                                <div class="submitcenter mb-4" *ngIf="formSuccess">
                                    <div class="col-12 mt-3 text-center">
                                        <div class="alert alert-success small">{{msg}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
    
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
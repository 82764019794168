import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, } from '@angular/forms';
import { ApiService } from '../api.service';
/**
 * @title Stepper overview
 */
@Component({
  selector: 'app-warrantycard',
  templateUrl: 'warrantycard.component.html',
  styleUrls: ['warrantycard.component.css']
})
export class WarrantycardComponent implements OnInit {

  Email: any;

  contactform:any
  msg:any;
  msg2: any;
  formSuccess:boolean=false
  formError:any;
  ngLoader:boolean=true;
  click : boolean = false;
  contactdetails:any
  loader = false
  buttondisable = false;
  homeform: any;
  num: number =1;
  activeTab: number = this.num;
  myForm1: FormGroup;
  myForm2: FormGroup;
  myForm3: FormGroup;
  error_msg1: string ="";
  error_msg2: string ="";
  error_msg3: string ="";
  finish : boolean = false;
  last_value: string = "";
  // constructor(private api:ApiService, private http: HttpClient) { }

  constructor(private fb: FormBuilder, private api:ApiService, private http: HttpClient) {

    this.myForm1 = this.fb.group({
      invoiceNo: ['', [Validators.required]],
      product: ['', [Validators.required]],
      quantity: ['', Validators.required],
      sku: ['', Validators.required],
      buckets: ['', Validators.required],
      completionDate: [null, Validators.required],
      manufactureDate: [null, Validators.required],
      purchaseDate: [null, Validators.required], // Date picker control
      // dropdown: [null, Validators.required] // Dropdown control
    });

    this.myForm2 = this.fb.group({
      dname: ['', [Validators.required]],
      dmobileno: ['', [Validators.required]],
      daddress: ['', Validators.required],
      cname: ['', Validators.required],
      cmobileno: ['', Validators.required]
    });

    this.myForm3 = this.fb.group({
      uname: ['', [Validators.required]],
      umobileno: ['', [Validators.required]],
      uemail: ['', Validators.required],
      uaddress: ['', Validators.required],
      ustate: ['', Validators.required],
      ucity: ['', Validators.required]
    });

  }

  ngOnInit(): void {

  //   this.contactform = new FormGroup({
  //     name: new FormControl('', Validators.required),
  //     invoiceNo: new FormControl('', Validators.required),
  //     quantity: new FormControl('', Validators.required),
  //     date: new FormControl('', Validators.required),
  //     dropdown: new FormControl('', Validators.required),
  //   });

  //   this.ngLoader = true;
  //   setTimeout(()=>{
  //       this.ngLoader = false;
  //   }, 1000);

  }

  nextTab1(): void {
    
    const { invoiceNo, product, sku, buckets, quantity, completionDate, manufactureDate, purchaseDate } = this.myForm1.value;

  if (!invoiceNo || !product || !sku || !buckets || !quantity || !completionDate || !manufactureDate || !purchaseDate) {

    this.error_msg1 = "Kindly fill the fields"

  } else {
    this.error_msg1 = ""
    this.last_value = product;

    if (this.activeTab < 3) {
      this.activeTab++;
      this.num = this.activeTab;
    }

  }


  
  }

  nextTab2(): void {
    
    const { dname, dmobileno, daddress, cname, cmobileno } = this.myForm2.value;

  if (((!dname || !dmobileno || !daddress ) && (!cname || !cmobileno ))) {

    this.error_msg2 = "Kindly fill the fields"
  } else {
    this.error_msg2 = ""


    if (this.activeTab < 3) {
      this.activeTab++;
      this.num = this.activeTab;
    }

  }


  
  }

  nextTab3(): void {
    
    const { uname, umobileno, uemail, uaddress, ustate, ucity } = this.myForm3.value;

  if (!uname || !umobileno || !uemail || !uaddress || !ustate || !ucity) {

    this.error_msg3 = "Kindly fill the fields"

  } else {

    this.error_msg3 = ""
    this.finish = true;
    

    let ebody = `
     <h1>PRODUCT DETAILS:  </h1>
     <br>
     Product : ${this.myForm1.value.product}
     <br>
     Purchase Date : ${this.myForm1.value.purchaseDate}
     <br>
     SKU : ${this.myForm1.value.sku}
     <br>
     No. of Buckets : ${this.myForm1.value.buckets}
     <br>
     Quantity in Litres : ${this.myForm1.value.quantity}
     <br>
     Mfg. Date : ${this.myForm1.value.manufactureDate}
     <br>
     Application Completion Date : ${this.myForm1.value.completionDate}
     <br>
     

     <h1>DEALER/CONTRACTOR DETAILS: </h1>
     <br>
     Dealer Name : ${this.myForm2.value.dname}
     <br>
     Dealer Mobile No : ${this.myForm2.value.dmobileno}
     <br>
     Dealer Address : ${this.myForm2.value.daddress}
     <br>
     Contractor Name : ${this.myForm2.value.cname}
     <br>
     Contractor Mobile No : ${this.myForm2.value.cmobileno}
     <br>


     <h1>USER DETAILS: </h1>
     <br>
     User's Name : ${this.myForm3.value.uname}
     <br>
     User's Mobile No : ${this.myForm3.value.umobileno}
     <br>
     User's Email : ${this.myForm3.value.uemail}
     <br>
     User's Address : ${this.myForm3.value.uaddress}
     <br>
     User's State : ${this.myForm3.value.ustate}
     <br>
     User's City : ${this.myForm3.value.ucity}
     <br>

     `;
    
     // @ts-ignore
    Email.send({
        SecureToken : "7b65edcd-4255-4083-bc8a-448e47810bd1", //add your token here
        To : 'nexonwarranty@gmail.com', 
        From : "nexonwarranty@gmail.com",
        Subject : "New Warranty",
        Body : ebody
    }).then(
      // message => alert(message)

    );
    
  }


  
  }
  lastsubmit(): void {
    let product = "";
    let warrantyHref = "";
  
    switch(this.last_value) {
      case "Rainmaster 7 in 1 Luxury Exterior Emulsion":
        product = "Rainmaster 7 in 1 Luxury Exterior Emulsion";
        warrantyHref = "../../assets/images/warranty/Rainmaster_Warranty Guide_24.pdf";
        break;
      case "Weatherflex Exterior Emulsion":
        product = "Weatherflex Exterior Emulsion";
        warrantyHref = "../../assets/images/warranty/Weatherflex_Warranty Guide_24.pdf";
        break;
      case "Damp Defend Waterproof Coating":
        product = "Damp Defend Waterproof Coating";
        warrantyHref = "../../assets/images/warranty/Damp Defend_Warranty Guide_28.pdf";
        break;
      default:
        console.error("Unknown product");
        return; // Exit the method if the product is unknown
    }
  
    if (warrantyHref) {
      // Open the warranty guide PDF in a new tab
      window.open(warrantyHref, "_blank");
    } else {
      console.log("No warranty guide available");
    }
  }

  save(): void {
    this.loader = true;
  }


  onButtonClick(event : MouseEvent){
    (event.target as HTMLButtonElement).disabled = true;
  }
  
  keyPressNumbers(event:any) {
    var charCode = (event.which) ? event.which : event.keyCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  // onSubmit() {
  //   this.buttondisable = true
  //   this.loader = true
  //   this.api.contactUs(this.contactform.value)
  //   .subscribe(
  //     val => {
  //       console.log(val)
  //       this.formSuccess=true
  //       this.msg=val
  //       this.msg=this.msg['data']['response']+' '+this.msg['data']['response2']
  //       this.loader = false
  //       this.contactform.reset()
  //       this.buttondisable = false
  //       setTimeout(
  //         ()=>{
  //           this.formSuccess=false
  //         },
  //       4000)
  //     },
  //     err=>{
  //       console.log(err)
  //       this.buttondisable = false
  //       this.msg=err.error.errors;
  //       this.loader = false
  //     }
  //   )
  // }



}

<section class="br-loc py-5">
    <div class="container">
        <h1 class="bt-title text-center">Branch Locator</h1>
        <div class="row justify-content-center mt-5">
            <div class="col-lg-7">
                <div class="row">
                    <div class="col-6">
                        <select name="" id="" #st class="form-control bcontrol" (change)="getCities(st.value)">
                            <option value="" selected disabled>Select State</option>
                            <ng-container *ngFor="let st of stateList">
                                <option [value]="st.id">{{st.name}}</option>
                            </ng-container>
                        </select>
                    </div>
                    <div class="col-6">
                        <select name="" id="" #ct class="form-control bcontrol" (change)="getBranches(ct.value)">
                            <option value="">Select City</option>
                            <ng-container *ngFor="let city of cityList">
                                <option [value]="city.id">{{city.city_name}}</option>
                            </ng-container>
                        </select>
                    </div>
                </div>
                <div class="text-center loader">
                    <div *ngIf="loading">
                        <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-10">
                <div class="row align-items-center">
                    <div class="col-md-6">
                        <div *ngIf="searched==true" style="max-height: 420px;overflow:auto">
                            <div class="br-add d-flex mb-4">
                                <i class="fa fa-map-marker mr-2"></i>
                                <div class="br-add-inner">
                                    <h4 class="br-name">Registered office</h4>
                                    <p class="br-desc"># Plot No.75 & 76 (P), EPIP, Pashamylaram Patancheru Mandal, Sangaredy District, Sangareddy, Telangana 502307</p>
                                </div>
                            </div>
                            <div class="br-add d-flex mb-4">
                                <i class="fa fa-phone mr-2"></i>
                                <div class="br-add-inner">
                                    <h4 class="br-name">Call Us</h4>
                                    <p class="br-desc">1800-120-8200</p>
                                </div>
                            </div>
                            <div class="br-add d-flex">
                                <i class="fa fa-envelope mr-2"></i>
                                <div class="br-add-inner">
                                    <h4 class="br-name">Get in Touch</h4>
                                    <p class="br-desc">care@nexonpaints.com</p>
                                </div>
                            </div>
                        </div>
                        <ng-container *ngFor="let br of branches; let i=index">
                            <div [ngClass]="{'mb-4': i<branches.length}" style="max-height: 420px;overflow:auto">
                                <div class="br-add d-flex mb-4">
                                    <i class="fa fa-map-marker mr-2"></i>
                                    <div class="br-add-inner">
                                        <h4 class="br-name">Branch  Office</h4>
                                        <p class="br-desc">{{br.address}} - {{br.pincode}}, {{br.city.city_name}}, {{br.state.name}}</p>
                                    </div>
                                </div>
                                <div class="br-add d-flex mb-4" *ngIf="br.phone.length>1">
                                    <i class="fa fa-phone mr-2"></i>
                                    <div class="br-add-inner">
                                        <h4 class="br-name">Call Us</h4>
                                        <p class="br-desc">{{br.phone}}</p>
                                    </div>
                                </div>
                                <div class="br-add d-flex" *ngIf="br.email.length>1">
                                    <i class="fa fa-envelope mr-2"></i>
                                    <div class="br-add-inner">
                                        <h4 class="br-name">Get in Touch</h4>
                                        <p class="br-desc">{{br.email}}</p>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <div class="col-md-6">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3804.6664460216266!2d78.16971581435506!3d17.5234290034808!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcbf1a4b52e1d6b%3A0xb687ecadeee98dec!2sNexon%20Paints%20Pvt.Ltd.!5e0!3m2!1sen!2sin!4v1634737918934!5m2!1sen!2sin" width="100%" height="420" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-knowmore></app-knowmore>
<section class="warrantybanner">
</section>

<section class="contactus" *ngIf="!finish">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-4 ">
        <div class="cont">

          <div class="image-container">
            <img src="../../assets/images/warranty/bucket1.jpg" alt="top image" class="bucket-img" />
            <a class="view-btn" >Rainmaster</a>
          </div>

          <div class="image-container">
            <img src="../../assets/images/warranty/bucket2.jpg" alt="top image" class="bucket-img" />
            <a class="view-btn" >Weather Flex</a>
          </div>


          <div class="image-container">
            <img src="../../assets/images/warranty/bucket3.jpg" alt="top image" class="bucket-img" />
            <a class="view-btn" >Damp Defend</a>
          </div>
        </div>

      </div>
      <div class="col-md-8 py-5 px-lg-4">







        <!-- <p>{{ activeTab }}</p> -->
        <div class="container">
          <ul class="nav nav-pills" id="myTabs">
            <li class="nav-item">
              <a href="#home" class="nav-link disabled" [class.active]="activeTab === 1"
                data-toggle="pill">PRODUCT<br /> DETAILS</a>
            </li>
            <li class="nav-item">
              <a href="#profile" class="nav-link disabled" [class.active]="activeTab === 2"
                data-toggle="pill">DEALER/CONTRACTOR<br /> DETAILS</a>
            </li>
            <li class="nav-item">
              <a href="#messages" class="nav-link disabled" [class.active]="activeTab === 3" data-toggle="pill">USER
                <br /> DETAILS</a>
            </li>
          </ul>

          <div class="tab-content mt-2">
            <div id="home" class="tab-pane fade" [ngClass]="{ 'show active': activeTab === 1 }">

              <form [formGroup]="myForm1" (ngSubmit)="nextTab1()">
                <div class="form-group">
                  <label for="product">Product *</label>
                  <select class="form-control" id="product" formControlName="product">
                    <option value="Rainmaster 7 in 1 Luxury Exterior Emulsion">Rainmaster 7 in 1 Luxury Exterior Emulsion</option>
                    <option value="Weatherflex Exterior Emulsion">Weatherflex Exterior Emulsion</option>
                    <option value="Damp Defend Waterproof Coating">Damp Defend Waterproof Coating</option>
                  </select>
                </div>

                <div class="form-group">
                  <label for="purchaseDate">Purchase Date *</label>
                  <input type="date" class="form-control" id="purchaseDate" formControlName="purchaseDate">
                </div>

                <div class="form-group">
                  <label for="invoiceNo">Purchase Invoice No *</label>
                  <input type="text" class="form-control" id="invoiceNo" formControlName="invoiceNo">
                </div>

                <div class="form-group">
                  <label for="sku">SKU Purchased *</label>
                  <select class="form-control" id="sku" formControlName="sku">
                    <option value="1 Litre">1 Litre</option>
                    <option value="4 Litres">4 Litres</option>
                    <option value="10 Litres">10 Litres</option>
                    <option value="20 Litres">20 Litres</option>
                  </select>
                </div>

                <div class="form-group">
                  <label for="buckets">No. of Buckets *</label>
                  <input type="number" class="form-control" id="buckets" formControlName="buckets" required>
                </div>

                <div class="form-group">
                  <label for="quantity">Quantity in Litres *</label>
                  <input type="number" class="form-control" id="quantity" formControlName="quantity" required>
                </div>

                <div class="form-group">
                  <label for="manufactureDate">Mfg. Date *</label>
                  <input type="date" class="form-control" id="manufactureDate" formControlName="manufactureDate">
                </div>

                <div class="form-group">
                  <label for="completionDate">Application Completion Date *</label>
                  <input type="date" class="form-control" id="completionDate" formControlName="completionDate">
                </div>
                <p class="text-danger">{{error_msg1}}</p>

                <button type="submit" class="btn btn-primary">Next</button>
              </form>

              <!-- <div class="form-group">
                    <label for="exampleInputEmail1">Email address</label>
                    <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" formControlName="email">
                    <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small>
                  </div> -->
              <!-- <div class="form-group">
                    <label for="exampleInputPassword1">Password</label>
                    <input type="password" class="form-control" id="exampleInputPassword1" placeholder="Password" formControlName="password">
                  </div> -->


              <!-- <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="exampleCheck1">
                    <label class="form-check-label" for="exampleCheck1">Check me out</label>
                  </div> -->


            </div>
            <div id="profile" class="tab-pane fade" [ngClass]="{ 'show active': activeTab === 2 }">

              <form [formGroup]="myForm2" (ngSubmit)="nextTab2()">
                <small id="emailHelp" class="form-text text-muted mb-3 ">Dealers Deatils</small>

                <div class="form-group">
                  <label for="dname">Name *</label>
                  <input type="text" class="form-control" id="dname" formControlName="dname" required>
                </div>



                <div class="form-group">
                  <label for="dmobileno">Mobile No *</label>
                  <input type="number" class="form-control" id="dmobileno" formControlName="dmobileno">
                </div>

                <div class="form-group">
                  <label for="daddress">Address *</label>
                  <input type="text" class="form-control" id="daddress" formControlName="daddress" required>
                </div>

                <small id="emailHelp" class="form-text text-muted or-control ">OR</small>
                <small id="emailHelp" class="form-text text-muted mb-3 ">Contractor Deatils</small>

                <div class="form-group">
                  <label for="cname">Name *</label>
                  <input type="text" class="form-control" id="cname" formControlName="cname" required>
                </div>



                <div class="form-group">
                  <label for="cmobileno">Mobile No *</label>
                  <input type="number" class="form-control" id="cmobileno" formControlName="cmobileno">
                </div>


                <p class="text-danger">{{error_msg2}}</p>
                <button type="submit" class="btn btn-primary">Next</button>
              </form>
            </div>
            <div id="messages" class="tab-pane fade" [ngClass]="{ 'show active': activeTab === 3 }">
              <form [formGroup]="myForm3" (ngSubmit)="nextTab3()">


                <div class="form-group">
                  <label for="uname">Name *</label>
                  <input type="text" class="form-control" id="uname" formControlName="uname" required>
                </div>



                <div class="form-group">
                  <label for="umobileno">Mobile No *</label>
                  <input type="number" class="form-control" id="umobileno" formControlName="umobileno">
                </div>

                <div class="form-group">
                  <label for="uemail">Email Id *</label>
                  <input type="email" class="form-control" id="uemail" formControlName="uemail" required>
                </div>


                <div class="form-group">
                  <label for="uaddress">Site Address *</label>
                  <input type="text" class="form-control" id="uaddress" formControlName="uaddress" required>
                </div>



                <div class="form-group">
                  <label for="ustate">State *</label>
                  <input type="text" class="form-control" id="ustate" formControlName="ustate">
                </div>


                <div class="form-group">
                  <label for="ucity">City *</label>
                  <input type="text" class="form-control" id="ucity" formControlName="ucity">
                </div>
                <p class="text-danger">{{error_msg3}}</p>

                <button type="submit" class="btn btn-primary">Submit</button>
              </form>
            </div>
          </div>
        </div>



        <!-- <div class="container">
              <h2>Dynamic Tabs</h2>
              <p>To make the tabs toggleable, add the data-toggle="tab" attribute to each link. Then add a .tab-pane class with a unique ID for every tab and wrap them inside a div element with class .tab-content.</p>
            
              <ul class="nav nav-pills">
                <li class="nav-item active"><a href="#home">Home</a></li>
                <li class="nav-item"><a  href="#menu1">Menu 1</a></li>
                <li class="nav-item"><a  href="#menu2">Menu 2</a></li>
                <li class="nav-item"><a  href="#menu3">Menu 3</a></li>
              </ul>
            
              <div class="tab-content">
                <div id="home" class="tab-pane fade">
                  <h3>HOME</h3>
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
                <div id="menu1" class="tab-pane fade in active">
                  <h3>Menu 1</h3>
                  <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                </div>
                <div id="menu2" class="tab-pane fade">
                  <h3>Menu 2</h3>
                  <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.</p>
                </div>
                <div id="menu3" class="tab-pane fade">
                  <h3>Menu 3</h3>
                  <p>Eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</p>
                </div>
              </div>
            </div> -->


      </div>
      

    </div>
  </div>
</section>

<section class="success-note" *ngIf="finish">
  <img src="../../assets/images/warranty/tick.webp" alt="tick" style="width: 180px; height: 180px;" />
  <h3 class="text-green ">
    Your warranty register successfully added.
  </h3>
  <h3>
    Thank you!
  </h3>
  <img src="assets/images/logo.png" alt="Nexon" class="logo" style="display: block;">
  <button  (click)="lastsubmit()" class="btn btn-primary">Download</button>
</section>
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, } from '@angular/forms';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  contactform:any
  msg:any;
  msg2: any;
  formSuccess:boolean=false
  formError:any;
  ngLoader:boolean=true;
  click : boolean = false;
  contactdetails:any
  loader = false
  buttondisable = false;
  homeform: any;

  constructor(private api:ApiService, private http: HttpClient) { }

  ngOnInit(): void {

    this.contactform = new FormGroup({
      name: new FormControl('', Validators.required),
      email: new FormControl('', Validators.required),
      mobile: new FormControl('', Validators.required),
      city: new FormControl('', Validators.required),
      requirement: new FormControl('', Validators.required),
    });

    this.ngLoader = true;
    setTimeout(()=>{
        this.ngLoader = false;
    }, 1000);

  }

  save(): void {
    this.loader = true;
  }
  onButtonClick(event : MouseEvent){
    (event.target as HTMLButtonElement).disabled = true;
  }
  
  keyPressNumbers(event:any) {
    var charCode = (event.which) ? event.which : event.keyCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  onSubmit() {
    this.buttondisable = true
    this.loader = true
    this.api.contactUs(this.contactform.value)
    .subscribe(
      val => {
        console.log(val)
        this.formSuccess=true
        this.msg=val
        this.msg=this.msg['data']['response']+' '+this.msg['data']['response2']
        this.loader = false
        this.contactform.reset()
        this.buttondisable = false
        setTimeout(
          ()=>{
            this.formSuccess=false
          },
        4000)
      },
      err=>{
        console.log(err)
        this.buttondisable = false
        this.msg=err.error.errors;
        this.loader = false
      }
    )
  }



}

<section class="product-range position-relative pt-4 pb-5">
    <!-- <div class="extra-float"><div class="container px-5"><div class="float-inner col-12"></div></div></div> -->
    <div class="container position-relative py-5">
        <div class="about-title-wrap justify-content-between align-items-center">
            <div class="about-title">
                <h2>Explore our</h2>
                <p>Product Range</p>
            </div>
            <!-- <img src="assets/images/home/bucket-model.png" alt="Spilled Bucket" class="about-title-img"> -->
        </div>
    </div>    
    <div class="container position-relative">
        <div class="product-range-inner px-md-5">
            <!-- <ul class="range-list px-0 mx-0 mx-md-5 d-md-flex justify-content-between" id="eco-count">
                <ng-container *ngFor="let cat of urlproducts; let i=index">
                 <li class="countchage" [ngClass]="{'active': i==0}" (click)="{{cat.slug}}"><span>{{cat.category}}</span></li>
                    <p>{{cat.name}}</p>
                </ng-container>
            </ul> -->
            
            <ul class="range-list px-0 mx-0 mx-md-5 d-md-flex justify-content-between" id="eco-count">
                <li class="countchage active" data-target="pro1"><span>Interior Emulsion</span></li>
                <li class="countchage" data-target="pro2" ><span>Exterior Emulsion</span></li>
                <li class="countchage" data-target="pro3" ><span>Wall Putty and Texture</span></li>
                <li class="countchage" data-target="pro4" ><span>Primers and Thinners</span></li>
                <li class="countchage" data-target="pro5" ><span>Wood Finishes & <br/>Specialty Emulsion</span></li>
            </ul>
        </div>
    </div>
</section>
<div class="changebit" id="changebit">
<section class="pro-list" id="pro1">
    <div class="container text-center mb-5">
        <div class="pro-heading mb-4">Interior Emulsion</div>
        <div class="pro-subheading">Make your inner spaces as vibrant as outside. Because hidden beauty is more enchanting. Satin smooth, classy, regal, graffiti, glowing bright, whatever may be the look you wish to create, find a perfect match here.</div>
    </div>
    <div class="container-fluid">
        <div class="mb-5 pb-5 row left-buck">
            <div class="col-md-6 bucket-img mb-4 mb-md-0"><img src="assets/images/products/bucket1.png" class="full" alt=""></div>
            <div class="col-md-6 p-0 text-center text-md-left"><img src="assets/images/products/int1.jpg" alt="" class="full"><a class="commmon-big-btn primary mt-4 mt-md-0" routerLink="/product/rubycon-luxury-emulsion">View Product</a></div>
        </div>
        <div class="mb-5 pb-5 row right-buck flex-md-row-reverse">
            <div class="col-md-6 bucket-img mb-4 mb-md-0"><img src="assets/images/products/bucket2.png" class="full" alt=""></div>
            <div class="col-md-6 p-0 text-center text-md-right"><img src="assets/images/products/int2.jpg" alt="" class="full"><a class="commmon-big-btn primary mt-4 mt-md-0" routerLink="/product/tiara-premium-interior-emulsion">View Product</a></div>
        </div>
        <div class="mb-5 pb-5 row left-buck">
            <div class="col-md-6 bucket-img mb-4 mb-md-0"><img src="assets/images/products/bucket3.png" class="full" alt=""></div>
            <div class="col-md-6 p-0 text-center text-md-left"><img src="assets/images/products/int3.jpg" alt="" class="full"><a class="commmon-big-btn primary mt-4 mt-md-0" routerLink="/product/supertrack-interier-emulsion-high-gloss">View Product</a></div>
        </div>
        <div class="mb-5 pb-5 row right-buck flex-md-row-reverse">
            <div class="col-md-6 bucket-img mb-4 mb-md-0"><img src="assets/images/products/bucket4.png" class="full" alt=""></div>
            <div class="col-md-6 p-0 text-center text-md-right"><img src="assets/images/products/int4.jpg" alt="" class="full"><a class="commmon-big-btn primary mt-4 mt-md-0" routerLink="/product/supertrack-interier-emulsion">View Product</a></div>
        </div>
        <!-- <div class="mb-5 pb-5 row left-buck">
            <div class="col-md-6 bucket-img mb-4 mb-md-0"><img src="assets/images/products/bucket20.png" class="full" alt=""></div>
            <div class="col-md-6 p-0 text-center text-md-left"><img src="assets/images/products/int5.jpg" alt="" class="full"><a class="commmon-big-btn primary mt-4 mt-md-0" routerLink="/product/supertrack-interier-emulsion-high-gloss1">View Product</a></div>
        </div> -->
    </div>
</section>

<section class="pro-list hidden" id="pro2">
    <div class="container text-center mb-5">
        <div class="pro-heading mb-3">Exterior Emulsion</div>
        <div class="pro-subheading">Make your inner spaces as vibrant as outside. Because hidden beauty is more enchanting. Satin smooth, classy, regal, graffiti, glowing bright, whatever may be the look you wish to create, find a perfect match here.</div>
    </div>
    <div class="container-fluid">
        <div class="mb-5 pb-5 row right-buck flex-md-row-reverse">
            <div class="col-md-6 bucket-img mb-4 mb-md-0"><img src="assets/images/products/bucket5.png" class="full" alt=""></div>
            <div class="col-md-6 p-0 text-center text-md-right"><img src="assets/images/products/product5.jpg" alt="" class="full"><a class="commmon-big-btn primary mt-4 mt-md-0" routerLink="/product/exterior-emulsion-rain-master">View Product</a></div>
        </div>
        <div class="mb-5 pb-5 row left-buck">
            <div class="col-md-6 bucket-img mb-4 mb-md-0"><img src="assets/images/products/bucket6.png" class="full" alt=""></div>
            <div class="col-md-6 p-0 text-center text-md-left"><img src="assets/images/products/product6.jpg" alt="" class="full"><a class="commmon-big-btn primary mt-4 mt-md-0" routerLink="/product/exterior-emulsion-wather-flex">View Product</a></div>
        </div>
        <div class="mb-5 pb-5 row right-buck flex-md-row-reverse">
            <div class="col-md-6 bucket-img mb-4 mb-md-0"><img src="assets/images/products/bucket7.png" class="full" alt=""></div>
            <div class="col-md-6 p-0 text-center text-md-right"><img src="assets/images/products/product7.jpg" alt="" class="full"><a class="commmon-big-btn primary mt-4 mt-md-0" routerLink="/product/exterior-emulsion-espro">View Product</a></div>
        </div>

    </div>
</section>


<section class="pro-list hidden" id="pro3">
    <div class="container text-center mb-5">
        <div class="pro-heading mb-3">Wall Putty and Texture</div>
        <div class="pro-subheading">Make your inner spaces as vibrant as outside. Because hidden beauty is more enchanting. Satin smooth, classy, regal, graffiti, glowing bright, whatever may be the look you wish to create, find a perfect match here.</div>
    </div>
    <div class="container-fluid">
        <div class="mb-5 pb-5 row right-buck flex-md-row-reverse">
            <div class="col-md-6 bucket-img mb-4 mb-md-0"><img src="assets/images/products/bucket8.png" class="full" alt=""></div>
            <div class="col-md-6 p-0 text-center text-md-right"><img src="assets/images/products/product8.jpg" alt="" class="full"><a class="commmon-big-btn primary mt-4 mt-md-0" routerLink="/product/wall-bond-putty">View Product</a></div>
        </div>
        <div class="mb-5 pb-5 row left-buck">
            <div class="col-md-6 bucket-img mb-4 mb-md-0"><img src="assets/images/products/bucket9.png" class="full" alt=""></div>
            <div class="col-md-6 p-0 text-center text-md-left"><img src="assets/images/products/product9.jpg" alt="" class="full"><a class="commmon-big-btn primary mt-4 mt-md-0" routerLink="/product/sai-coat">View Product</a></div>
        </div>
      
        <div class="mb-5 pb-5 row right-buck flex-md-row-reverse">
            <div class="col-md-6 bucket-img mb-4 mb-md-0"><img src="assets/images/products/bucket10.png" class="full" alt=""></div>
            <div class="col-md-6 p-0 text-center text-md-right"><img src="assets/images/products/product10.jpg" alt="" class="full"><a class="commmon-big-btn primary mt-4 mt-md-0" routerLink="/product/mr-premium">View Product</a></div>
        </div>

    </div>
</section>

<section class="pro-list hidden" id="pro4">
    <div class="container text-center mb-5">
        <div class="pro-heading mb-3">Primers and Thinners</div>
        <div class="pro-subheading">Make your inner spaces as vibrant as outside. Because hidden beauty is more enchanting. Satin smooth, classy, regal, graffiti, glowing bright, whatever may be the look you wish to create, find a perfect match here.</div>
    </div>
    <div class="container-fluid">
        <div class="mb-5 pb-5 row right-buck flex-md-row-reverse">
            <div class="col-md-6 bucket-img mb-4 mb-md-0"><img src="assets/images/products/bucket11.png" class="full" alt=""></div>
            <div class="col-md-6 p-0 text-center text-md-right"><img src="assets/images/products/product11.jpg" alt="" class="full"><a class="commmon-big-btn primary mt-4 mt-md-0" routerLink="/product/metal-primar">View Product</a></div>
        </div>
        <div class="mb-5 pb-5 row left-buck">
            <div class="col-md-6 bucket-img mb-4 mb-md-0"><img src="assets/images/products/bucket12.png" class="full" alt=""></div>
            <div class="col-md-6 p-0 text-center text-md-left"><img src="assets/images/products/product12.jpg" alt="" class="full"><a class="commmon-big-btn primary mt-4 mt-md-0" routerLink="/product/prime-time">View Product</a></div>
        </div>
      
        <div class="mb-5 pb-5 row right-buck flex-md-row-reverse">
            <div class="col-md-6 bucket-img mb-4 mb-md-0"><img src="assets/images/products/bucket13.png" class="full" alt=""></div>
            <div class="col-md-6 p-0 text-center text-md-right"><img src="assets/images/products/product13.jpg" alt="" class="full"><a class="commmon-big-btn primary mt-4 mt-md-0" routerLink="/product/aulto-prime">View Product</a></div>
        </div>
        <div class="mb-5 pb-5 row left-buck">
            <div class="col-md-6 bucket-img mb-4 mb-md-0"><img src="assets/images/products/bucket14.png" class="full" alt=""></div>
            <div class="col-md-6 p-0 text-center text-md-left"><img src="assets/images/products/product14.jpg" alt="" class="full"><a class="commmon-big-btn primary mt-4 mt-md-0" routerLink="/product/vivo-water-based-primer">View Product</a></div>
        </div>

        <div class="mb-5 pb-5 row right-buck flex-md-row-reverse">
            <div class="col-md-6 bucket-img mb-4 mb-md-0"><img src="assets/images/products/bucket15.png" class="full" alt=""></div>
            <div class="col-md-6 p-0 text-center text-md-right"><img src="assets/images/products/product15.jpg" alt="" class="full"><a class="commmon-big-btn primary mt-4 mt-md-0" routerLink="/product/vivo-water-based-primer-interior">View Product</a></div>
        </div>
        <div class="mb-5 pb-5 row left-buck">
            <div class="col-md-6 bucket-img mb-4 mb-md-0"><img src="assets/images/products/bucket16.png" class="full" alt=""></div>
            <div class="col-md-6 p-0 text-center text-md-left"><img src="assets/images/products/product16.jpg" alt="" class="full"><a class="commmon-big-btn primary mt-4 mt-md-0" routerLink="/product/premium-enamel">View Product</a></div>
        </div>
    </div>
</section>

<section class="pro-list hidden" id="pro5">
    <div class="container text-center mb-5">
        <div class="pro-heading mb-3">Wood Finishes & Spciality Emulsions</div>
        <div class="pro-subheading">Make your inner spaces as vibrant as outside. Because hidden beauty is more enchanting. Satin smooth, classy, regal, graffiti, glowing bright, whatever may be the look you wish to create, find a perfect match here.</div>
    </div>
    <div class="container-fluid">
        <div class="mb-5 pb-5 row left-buck">
            <div class="col-md-6 bucket-img mb-4 mb-md-0"><img src="assets/images/products/bucket17.png" class="full" alt=""></div>
            <div class="col-md-6 p-0 text-center text-md-left"><img src="assets/images/products/product17.jpg" alt="" class="full"><a class="commmon-big-btn primary mt-4 mt-md-0" routerLink="/product/richwood-premium-wood-finishes">View Product</a></div>
        </div>
        <div class="mb-5 pb-5 row right-buck flex-md-row-reverse">
            <div class="col-md-6 bucket-img mb-4 mb-md-0"><img src="assets/images/products/bucket18.png" class="full" alt=""></div>
            <div class="col-md-6 p-0 text-center text-md-right"><img src="assets/images/products/product18.jpg" alt="" class="full"><a class="commmon-big-btn primary mt-4 mt-md-0" routerLink="/product/premium-metallic-emulsion">View Product</a></div>
        </div>
        <div class="mb-5 pb-5 row left-buck">
            <div class="col-md-6 bucket-img mb-4 mb-md-0"><img src="assets/images/products/bucket19.png" class="full" alt=""></div>
            <div class="col-md-6 p-0 text-center text-md-left"><img src="assets/images/products/product19.jpg" alt="" class="full"><a class="commmon-big-btn primary mt-4 mt-md-0" routerLink="/product/ceiling-white">View Product</a></div>
        </div>
    </div>
</section>
</div>
<app-knowmore></app-knowmore>
import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {

  constructor(private api:ApiService) { }
  NewsList:any;
  async ngOnInit(){
    this.NewsList = await this.api.getNews()
    console.log(this.NewsList)
    this.NewsList.forEach((s:any)=>s.image=this.api.baseimgurl+s.image)
    console.log(this.NewsList)
  }

}

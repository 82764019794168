import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-joinus',
  templateUrl: './joinus.component.html',
  styleUrls: ['./joinus.component.css']
})
export class JoinusComponent implements OnInit {

  careerForm:FormGroup;
  file: any;
  msg:any;
  buttondisable: boolean = false;
  loader:boolean = false;
  formSuccess:boolean = false;
  careerPositions :[];

  constructor(private fb: FormBuilder, private api:ApiService) {
    this.careerForm = this.fb.group({
      name : ['',Validators.required ],
      email: ['',Validators.required ],
      phone: ['',Validators.required ],
      file: ['',Validators.required ],
      position: ['HR Manager',Validators.required ],
    })
   }

  colorOption: OwlOptions  = {
    items: 1,
    autoplay: false,
    loop:false,
    margin:0,
    dots: true,
    nav: false
  }

  onChange(event) {
    this.file = event.target.files[0];
  } 

  
  position:string;
  ngOnInit(): void {
    this.position = 'HR Manager';
   // this.careerForm.setValue({ position: 'Project Sales' })

    this.api.gerCareerData()
            .subscribe(
            (res:any) => {
                this.careerPositions =  res.data.career;
                this.position = res.data.career[0].position_name;
              },
              err => {

              }
            )

    }

  setPosition(p) {
    this.position = p;
    this.careerForm.setValue({ 
      position: p,
      name :'',
      email:'',
      phone: '',
      file: ''
     })
  }

  onSubmit(){
    const formData = new FormData(); 
    formData.append('name', this.careerForm.get('name').value);
    formData.append('email', this.careerForm.get('email').value);
    formData.append('phone', this.careerForm.get('phone').value);
    formData.append('position', this.careerForm.get('position').value);
    formData.append('file', this.file);
    this.buttondisable = true
    this.loader = true
    this.api.postCareer(formData)
    .subscribe(
      val => {
        console.log(val)
        this.formSuccess=true
        this.msg=val
        this.msg=this.msg['data']['response']+' '+this.msg['data']['response2']
        this.loader = false
        this.careerForm.reset()
        this.buttondisable = false
        setTimeout(
          ()=>{
            this.formSuccess=false
          },
        4000)
      },
      err=>{
        console.log(err)
        this.buttondisable = false
        this.msg=err.error.errors;
        this.loader = false
      }
    )
  }

}

<div class="about-heading">
    <div class="container">About Us</div>
</div>
<section class="about-top">
    <div class="container-fluid pad0">
        <div class="d-flex align-items-start flex-md-row-reverse">
            <div class="col-md-5 pad0">
                <img (click)="openPopup()" class="img-fluid" src="assets/images/home/nexon_banner.PNG">
                <div class="playvideo">
                    <a (click)="openPopup()"><img src="assets/images/about/play.png"> Our business Video</a>
                </div>
            </div>
            <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyle}">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                    <div class="modal-body">
                        <div class="text-right imgvideo py-3"><img (click)="closePopup()" src="assets/images/home/close.svg"></div>
                        <video width="100%" height="auto" loop="true" controls muted id="myVideo"><source src="assets/images/home/nexon_video.mp4" type="video/mp4"></video>
                    </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 offset-1">
                <div class="about-c">
                  <div class="mainp">To Create Vibrant Spaces, One Needs Vibrant Ideas. And At Nexon, This Is What We Focus Upon.</div>
                  <p>Started with an aim to manufacture high quality, easy to afford products, Nexon is a fast growing company. Formerly known as Saicoat Paints, the company has acquired a decent share in the market as well as a strong base of happy customers. And now, we are all set to take the next big leap. For doing so, we have incorporated a sequence of changes which include – adoption of an internationally appealing identity, inclusion of a professional management structure and a new team of sharp minds with acute business sense.
                  </p>
                  <p>With a strong vision for the future and openness to fresh ideas, Nexon gears up to spearhead on its new trajectory and make its mark across India, by 2022.</p>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="whatwedo">
    <div class="container-fluid pad0">
        <div class="flex">
            <div class="col-12 col-sm-12 col-md-6 pad0">
                <owl-carousel-o [options]="colorOption">
                    <ng-template carouselSlide>
                        <img src="assets/images/about/2.jpg">
                        <div class="overlay-post green">Interior Design</div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <img src="assets/images/about/3.jpg">
                        <div class="overlay-post blue">Interior Design</div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <img src="assets/images/about/4.jpg">
                        <div class="overlay-post purple">Interior Design</div>
                    </ng-template>
                </owl-carousel-o>
                
            </div>
            
            <div class="col-12 col-sm-12 col-md-5">
                <h1>What we do</h1>
                <p>Nexon Paints, headquartered in Hyderabad, is a leading manufacturer and marketer of Decorative Paints in the country.</p>
                <p>Our Paint portfolio includes Interior and Exterior Emulsions, Primers, Solvent based products and Putty/Texture</p>
                <p>Over the past 10 years, we have created a reputation of providing very good quality products and excellent customer service. Thus we have emerged as the brand of choice for Painters, Contractors, and Builders.</p>
                <p>We provide excellent value for money to our customers. We also believe in product innovation whilst at the same time being environment friendly.</p>
            </div>
        </div>
    </div>
    
    
<div class="sectionformission">
        <div class="centerarea">
            <div class="bg-main-c col-md-12">
                  <div class="row">
                    <div class="col-12 col-sm-12 col-md-4 offset-7">
                        <h2>Values </h2>
                        <ul>
                            <li>Integrity above all</li>
                            <li>Flawless Service</li>
                            <li>Transparent Ecosystem</li>
                        </ul>
                    </div>
                  </div>
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-4 offset-4">
                        <h2>Mission </h2>
                            <p class="exwith">We would like to be one of the trusted player in India in the decorative paints segment.</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-4 offset-1 tagseows">
                        <h2>Vision </h2>
                        <ul>
                            <li>Offer the best in class value proposition with regards to the performance of our paints.
                            </li>
                            <li>Nurturing a winning network of partners and building mutual loyalty.
                            </li>
                            <li>Merit and performance based approach within our ecosystem which will create winning teams.
                            </li>
                            <li>Care for the environment</li>
                        </ul>
                    </div>
                </div>
    </div>
</div>
</div>
</section>

<section class="ourteam pb-5 pt-md-5 pt-0">
    <div class="container">
        <div class="hedingteam">Our Team</div>
        <div class="row">
            <div class="col-12 col-sm-12 col-md-6 col-xs-6">
                <h3>MR. KHARTARAM CHOUDHARY</h3>
                <p>Mr Khartaram Choudhary was appointed as the Managing director of Nexon Paints. He has been actively involved in the creation and expansion of Nexon Paints. He has more than 30 years of Experience in paints and Coatings domain.</p>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-xs-6">
                <h3>MR. AJOY MUNDKUR</h3>
                <p>With more than 30 years of experience in Marketing, Sales and as Business head in various MNCs like ICI India, Gulf Oil, Valvoline and Huntsman, Mr. Ajoy Mundkur brings tremendous experience and a process or systems based approach to the company. An MBA from the batch of 1990, he has been instrumental in the turnaround at Nexon Paints which he has been associated with since 2016. He has been featured in 25 Most Valuable CEO’s in India 2021 by the Business Connect Magazine.</p>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-xs-6">
                <h3>MR. PRAVEEN CHOUDHARY</h3>
                <p>Mr Praveen Choudhary exemplifies creativity, passion and growth. He adds tremendous value to Nexon Paints with his in-depth industry experience and an eye for detail. While sharing his advice on various key aspects of business, Praveen has been focussing his energies on expanding the presence of Nexon Paints across all key markets in India. 
                A man of few words, Praveen not only leads business development but also strives to ensure high levels of performance from the team, aligned to the vision and mission of the company.</p>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-xs-6">
                <h3>MR. MILAN KUNDU CHAUDHARY</h3>
                <p>Mr. Milan Chaudhury has more than 40 years of experience in the paints industry mainly with two paint majors namely Berger and Akzo Nobel. He has been in leadership roles in various departments like QC/R&D/Plant Support and Production.</p>
            </div>
            
            
        </div>
    </div>
</section>

<section class="clientslogos">
    <div class="container">
        <div class="row">
            <div class="col-md-4 clients-heding">
                <h1>Our<br/> Clients</h1>
            </div>
            <div class="col-md-8">
                <div class="row logosrow">
                    <div class="col-6 col-sm-6 col-md-3"><img src="assets/images/about/1.png"></div>
                    <div class="col-6 col-sm-6 col-md-3"><img src="assets/images/about/2.png"></div>
                    <div class="col-6 col-sm-6 col-md-3"><img src="assets/images/about/3.png"></div>
                    <div class="col-6 col-sm-6 col-md-3"><img src="assets/images/about/4.png"></div>
                    <div class="seprate"></div>
                    <div class="col-6 col-sm-6 col-md-3"><img src="assets/images/about/5.png"></div>
                    <div class="col-6 col-sm-6 col-md-3"><img src="assets/images/about/6.png"></div>
                    <div class="col-6 col-sm-6 col-md-3"><img src="assets/images/about/7.png"></div>
                    <div class="col-6 col-sm-6 col-md-3"><img src="assets/images/about/8.png"></div>
                    <div class="seprate"></div>
                    <div class="col-6 col-sm-6 col-md-3"><img src="assets/images/about/9.png"></div>
                    <div class="col-6 col-sm-6 col-md-3"><img src="assets/images/about/10.png"></div>
                    <div class="col-6 col-sm-6 col-md-3"><img src="assets/images/about/11.png"></div>
                    <div class="col-6 col-sm-6 col-md-3"><img src="assets/images/about/12.png"></div>
                    <div class="seprate"></div>
                    <div class="col-6 col-sm-6 col-md-3 offset-6"><img src="assets/images/about/13.png"></div>
                    <div class="col-6 col-sm-6 col-md-3"><img src="assets/images/about/14.png"></div>
                </div>
            </div>
        </div>
    </div>
</section>


<section class="developmentlab">
    <div class="container-fluid pad0">
        <div class="flex">
                <div class="col-md-5 offset-1">
                    <h1>Development Lab</h1>
                    <div class="mainp">Mere vibrant ideas are not enough to create a range of outstanding products. What’s also imperative is the execution of an idea.</div>
                    <p>Precisely why, we own an ultra-modern plant in the beautiful city of Nizams. Situated amidst the beautiful environs of Pashamylaram, the Nexon plant is a picturesque place and apt to boost vibrant ideas. Built to deliver the best quality paints and ancillary products, the plant is well-equipped with advanced machinery. Along with this, the staff manning the machines is also intensively trained to reduce wastage during production, avoid breakdowns and time lag in the production cycle.</p>
                    <p>Holding quality as top priority, the production process includes multi-level checks and controls to deliver nothing less than the best.</p>
                </div>
                <div class="col-md-6 pad0">
                    <owl-carousel-o [options]="colorOption">
                        <ng-template carouselSlide>
                            <img src="assets/images/about/d-lab.jpg">
                        </ng-template>
                        <!-- <ng-template carouselSlide>
                            <img src="assets/images/about/empty_image.jpg">
                        </ng-template>
                        <ng-template carouselSlide>
                            <img src="assets/images/about/empty_image.jpg">
                        </ng-template>
                        <ng-template carouselSlide>
                            <img src="assets/images/about/empty_image.jpg">
                        </ng-template>
                        <ng-template carouselSlide>
                            <img src="assets/images/about/empty_image.jpg">
                        </ng-template> -->
                    </owl-carousel-o>
                    
                </div>
            </div>
    </div>
</section>


<section class="footertoprow py-3">
    <div class="container">
    <div class="row">
        <div class="col-md-6">
            <img class="img-fluid" src="assets/images/about/research_development.jpg">
        </div>
        <div class="col-md-6">
            <h1>R&D Facility</h1>
            <h6>Our in-depth and qualitative research paves way for innovation and that blooms happiness on the faces of ourcustomers.</h6>
            <p>The R & D wing strives to upgrade the existing products with advanced  technology and innovate new things simultaneously. </p><p>Our strong team of qualified technologists and scientists firmly supports the company’s business  plan and marketing needs. Our R&D laboratories are fully equipped with state-of-the-art scientific instruments, sophisticated testing and analytical  instruments, color matching softwares,  automatic dispensing machines,accelerated weathering equipment for developing, standardizing and testing our products. </p>
                <p>Apart from this, R&D extends valuable support to other functions like manufacturing, sourcing in terms of  process stansardizations, product trainings, raw material efficacy amongst many.</p>
        </div>
        <div class="col-md-6">
           <div class="contenttzt">
                <h1>Manufacturing Facility</h1>
                <h6>Our factory is located in Pashyamylaram which is on the outskirts of Hyderabad.</h6>
                <p> It houses state of the art technology and modern amenities for the manufacture of premium quality textures and paints, and is taken care of by a team of experienced professionals who have been with the company for many years.</p>
            </div>
        </div>
        <div class="col-md-6">
            <img class="img-fluid" src="assets/images/about/manufacturing-facility.jpg">
        </div>
    </div>
</div>
</section>

<app-knowmore></app-knowmore>

 <!-- The Modal -->
 <div class="modal" id="myModal">
    <div class="modal-dialog">
      <div class="modal-content">
      
        <!-- Modal Header -->
        <div class="modal-header">
          <h4 class="modal-title"> Our Business Video</h4>
          <button type="button" class="close" data-dismiss="modal">&times;</button>
        </div>
        
        <!-- Modal body -->
        <div class="modal-body">
          <img class="img-fluid" src="assets/images/about/video-picture.jpg">
        </div>
        
        <!-- Modal footer -->
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
        </div>
        
      </div>
    </div>
  </div>

<section class="pro-list pt-4">

        <div class="container text-center mb-5">
            <div class="pro-heading mb-4">All Products</div>
            <div class="pro-subheading">Make your inner spaces as vibrant as outside. Because hidden beauty is more enchanting. Satin smooth, classy, regal, graffiti, glowing bright, whatever may be the look you wish to create, find a perfect match here.</div>
        </div>
        <ng-container *ngFor="let pro of getproducts">
            <div class="container-fluid">
                <div class="mb-5 pb-5 row" [ngClass]="{'left-buck': pro.alignment=='left', 'right-buck flex-md-row-reverse':pro.alignment=='right'}">
                    <div class="col-md-6 bucket-img mb-4 mb-md-0"><img [src]="pro?.thumbnail" class="full"></div>
                    <div class="col-md-6 p-0 text-center text-md-left"><img [src]="pro?.p_image" class="full"><a class="commmon-big-btn primary mt-4 mt-md-0" routerLink="/{{pro.category.slug}}/{{pro.slug}}">View Product</a></div>
                </div>
                <!-- <div class="mb-5 pb-5 row right-buck flex-md-row-reverse" *ngIf="pro.alignment=='right'">
                    <div class="col-md-6 bucket-img mb-4 mb-md-0"><img [src]="pro?.thumbnail" class="full"></div>
                    <div class="col-md-6 p-0 text-center text-md-left"><img [src]="pro?.p_image" class="full"><a class="commmon-big-btn primary mt-4 mt-md-0" routerLink="/product/{{pro.slug}}">View Product</a></div>
                </div> -->
            </div>
        </ng-container>
      
</section>
<app-knowmore></app-knowmore>

<section class="pro-list pt-4">
    <div class="pro-heading-main text-center my-5">{{categoryTitle}}</div>
    <div *ngIf="isSubcategory">
        <ng-container *ngFor="let sub of Category | slice:0:Category.length-1">
            <div class="container text-left mb-5">
                <div class="row">
                    <div class="col-lg-6 col-md-8">
                        <div class="pro-heading mb-4">{{sub.name}}</div>
                        <!-- <div class="pro-subheading" [innerHtml] = "sub.desc"></div> -->
                    </div>
                </div>
            </div>
            <div class="container-fluid" *ngFor="let pr of sub.products">
                <div *ngIf="pr.alignment == 'left'">
                    <div class="mb-5 pb-5 row left-buck">
                        <div class="col-md-6 bucket-img mb-4 mb-md-0"><img [src]="pr?.thumbnail" class="full"></div>
                        <div class="col-md-6 p-0 text-center text-md-left"><img [src]="pr?.p_image" class="full"><a class="commmon-big-btn primary mt-4 mt-md-0" routerLink="/{{firstid}}/{{pr.sub_category_slug}}/{{pr.slug}}">Know More</a></div>
                    </div>
                </div>
                <div *ngIf="pr.alignment == 'right'">
                    <div class="mb-5 pb-5 row right-buck flex-md-row-reverse">
                        <div class="col-md-6 bucket-img mb-4 mb-md-0"><img [src]="pr?.thumbnail" class="full"></div>
                        <div class="col-md-6 p-0 text-center text-md-right"><img [src]="pr?.p_image" class="full"><a class="commmon-big-btn primary mt-4 mt-md-0" routerLink="/{{firstid}}/{{pr.sub_category_slug}}/{{pr.slug}}">Know More</a></div>
                    </div>
                </div>
            </div>
      </ng-container>
    </div>
    <div *ngIf="!isSubcategory">
        <ng-container *ngFor="let slug of Category">
            <div class="container-fluid">
                <div *ngIf="slug.alignment == 'left'">
                    <div class="mb-5 pb-5 row left-buck">
                        <div class="col-md-6 bucket-img mb-4 mb-md-0"><img [src]="slug?.thumbnail" class="full"></div>
                        <div class="col-md-6 p-0 text-center text-md-left"><img [src]="slug?.p_image" class="full"><a class="commmon-big-btn primary mt-4 mt-md-0" routerLink="/{{firstid}}/{{slug.sub_category_slug}}/{{slug.slug}}">Know More</a></div>
                    </div>
                </div>
                <div *ngIf="slug.alignment == 'right'">
                    <div class="mb-5 pb-5 row right-buck flex-md-row-reverse">
                        <div class="col-md-6 bucket-img mb-4 mb-md-0"><img [src]="slug?.thumbnail" class="full"></div>
                        <div class="col-md-6 p-0 text-center text-md-right"><img [src]="slug?.p_image" class="full"><a class="commmon-big-btn primary mt-4 mt-md-0" routerLink="/{{firstid}}/{{slug.sub_category_slug}}/{{slug.slug}}">Know More</a></div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</section>

<app-knowmore></app-knowmore>
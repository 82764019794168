<header>
  <div class="container">
      <nav class="navbar navbar-expand-lg navbar-light px-0 py-0"> 
        <a class="navbar-brand" routerLink="/"><img src="assets/images/logo.png" alt="Nexon" class="logo"></a>
        <a href="#" class="navbar-brand font-weight-bold d-block d-lg-none"></a> <button type="button" data-toggle="collapse" data-target="#navbarContent" aria-controls="navbars" aria-expanded="false" aria-label="Toggle navigation" class="navbar-toggler"> <span class="navbar-toggler-icon"></span> </button>
        <div id="navbarContent" class="collapse navbar-collapse">
            <ul class="navbar-nav ml-auto">
              <li class="nav-item" class="about">
                <a class="nav-link active" routerLink="/about">About Us</a>
                <!-- <div class="showabout">
                  <ul>
                    <li><a routerLink="/downloads">Downloads</a></li>
                    <li><a routerLink="javascript:void(0);">News & Media</a></li>
                    <li><a routerLink="/blogs">Blogs</a></li>
                    <li><a routerLink="/faq">FAQ's</a></li>
                  </ul>
                </div> -->
              </li>
              <li class="nav-item dropdown megamenu"><a id="megamneu" href="javascript:void(0);" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="nav-link dropdown-toggle ">Products</a>
                  <div aria-labelledby="megamneu" class="dropdown-menu p-0 m-0 py-5">
                      <div class="container">
                        <div class="row">
                          <div class="col-12 col-sm-12 col-md-12 listnav px-4">
                            <ul>
                              <div class="row">
                                <ng-container *ngFor="let c of catList">
                                  <div class="col-md-3">
                                    <img [src]="c?.p_image" class="full">
                                    <div class="centerdiv">
                                      <a href="{{c.slug}}">{{c.category}}</a>
                                    </div>
                                  </div>
                                </ng-container>
                              </div>
                            </ul>
                          </div>
                      </div>
                      </div>
                  </div>
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLink="/vibrant-ideas">Vibrant Ideas</a>
                </li>
                
                <li class="nav-item">
                  <a class="nav-link" routerLink="/warranty">Warranty Card</a>
                </li>
                <!-- <li class="nav-item">
                  <a class="nav-link" routerLink="/paint-guide">Paints Guide</a>
                </li> -->
                <li class="nav-item dropdown">
                  <a class="nav-link dropdown-toggle" routerLink="/paint-guide" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Color Guide
                  </a>
                  <i class="toggle-sub"></i>
                  <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <a class="dropdown-item" routerLink="/paint-guide">Paints Guide</a>
                    <a class="dropdown-item" routerLink="/colors">2200 Colors</a>
                  </div>
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLink="/joinus">Join Us</a>
                </li>
                <li class="nav-item dropdown">
                  <a class="nav-link dropdown-toggle" routerLink="/contact" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Contact Us
                  </a>
                  <i class="toggle-sub"></i>
                  <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <a class="dropdown-item" routerLink="/branch-locator">Branch Locator</a>
                    <a class="dropdown-item" routerLink="/contact">Enquiry</a>
                  </div>
                </li>
                <!-- <li class="nav-item" class="contact">
                  <a class="nav-link" routerLink="/contact">Contact Us <span><i class="fa fa-sort-desc" aria-hidden="true"></i></span> </a>
                  <div class="showcontact">
                    <ul>
                      <li><a routerLink="/branch-locator">Branch Locator</a></li>
                      <li><a routerLink="/contact">Enquiry</a></li>
                    </ul>
                  </div>
                </li> -->
              </ul>
              <a target="_blank" href="tel:18001208200" class="ml-2 ml-lg-5 header-phone">1800-120-8200</a>
        </div>
      </nav>
      
</div>
</header>
import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-blogs',
  templateUrl: './blogs.component.html',
  styleUrls: ['./blogs.component.css']
})
export class BlogsComponent implements OnInit {

  constructor(private api:ApiService) { }
  blogList: any

  async ngOnInit() {
    this.blogList = await this.api.getBlogs()
    this.blogList=this.blogList['response']
    this.blogList.forEach((s:any)=>s.image=this.api.baseimgurl+s.image)
  }


}


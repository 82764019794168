<section class="blog-sec position-relative py-5">
    <div class="extra-float"><div class="container px-5"><div class="float-inner col-12"></div></div></div>
    <div class="container text-center custom-container py-5 position-relative">
        <div class="about-title-wrap justify-content-between align-items-center">
            <div class="about-title">
                <h2>Blogs</h2>
            </div>
            <img src="assets/images/home/bucket-model.png" alt="Spilled Bucket" class="about-title-img">
        </div>
        <p class="blg-desc">Get Inspired by Vibrant ideas for interior, exterior design and much more through our blogs </p>
    </div> 
</section>

<div class="blog-main">
    <div class="container pb-5 mb-5">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="row">
                    <ng-container *ngFor="let blg of blogList">
                        <a class="col-lg-4 col-sm-6 blog-grid mb-5" routerLink="/blogs/{{blg.slug}}">
                            <img [src]="blg?.image" class="full">
                            <div class="blg-cap d-flex justify-content-between">
                                <span class="d-flex align-items-center"><i class="fa fa-thumbs-o-up mr-2"></i>{{blg.likes_count}} Likes</span>
                                <span class="d-flex align-items-center"><i class="fa fa-comment-o mr-2"></i>{{blg.count_comment}} Comments</span>
                            </div>
                            <!-- <ul class="blg-tags"><li>Interior Design</li><li>Lorem Ipsum</li></ul> -->
                            <h4 class="blog-title my-4">{{blg.heading}}</h4>
                            <div class="blog-meta">
                                <span class="mr-3"><i class="fa fa-user-o mr-2"></i>{{blg.author}}</span>
                                <span><i class="fa fa-calendar-o mr-2"></i>{{blg.date}}</span>
                            </div>
                        </a>
                    </ng-container>

                   
                </div>
            </div>
            <!-- <div class="col-lg-3 col-md-4">
                <div class="blg-sidebar"> -->
                    <!-- <div class="side-title d-flex justify-content-between">
                        <span>Filters</span>
                        <span>Clear All</span>
                    </div>
                    <select name="" id="" class="form-control filter-control">
                        <option value="Latest" selected>Latest</option>
                        <option value="Oldest">Oldest</option>
                    </select>
                    <select name="" id="" class="form-control filter-control">
                        <option value="Latest" selected>Category</option>
                        <option value="Oldest">Oldest</option>
                    </select>
                    <select name="" id="" class="form-control filter-control">
                        <option value="Latest" selected>Area</option>
                        <option value="Oldest">Oldest</option>
                    </select>
                    <select name="" id="" class="form-control filter-control">
                        <option value="Latest" selected>Archieves</option>
                        <option value="Oldest">Oldest</option>
                    </select> -->
                    <!-- <div class="side-title d-flex justify-content-between mt-3">
                        <span>Tags</span>
                    </div> -->
                    <!-- <ul class="sidetags">
                        <li>Lorem Ipsum</li>
                        <li>Lorem Ipsum</li>
                        <li>Lorem Ipsum</li>
                        <li>Lorem Ipsum</li>
                        <li>Lorem Ipsum</li>
                        <li>Lorem Ipsum</li>
                        <li>Lorem Ipsum</li>
                        <li>Lorem Ipsum</li>
                        <li>Lorem Ipsum</li>
                        <li>Lorem Ipsum</li>
                        <li>Lorem Ipsum</li>
                        <li>Lorem Ipsum</li>
                        <li>Lorem Ipsum</li>
                        <li>Lorem Ipsum</li>
                        <li>Lorem Ipsum</li>
                        <li>Lorem Ipsum</li>
                        <li>Lorem Ipsum</li>
                        <li>Lorem Ipsum</li>
                        <li>Lorem Ipsum</li>
                        <li>Lorem Ipsum</li>
                    </ul> -->
                <!-- </div> -->
            </div>
        </div>
    </div>

<section class="color-main py-5">
    <div class="container mb-5">
        <div class="main-title d-flex justify-content-center align-items-center">
            <img src="assets/images/home/colors-lg.png" class="mr-2" alt="">
            <h1 class="j1-title">2200 Colors</h1>
        </div>
    </div>
    <div class="draggable-colors">
        <ng-container *ngFor="let color of colors; let i=index">
            <div class="dcolor-single" (click)="getShades(i)" [ngStyle]="{'background-color': color?.varientCode}"><span class="varient-name" >{{color?.varientName}}</span></div>
        </ng-container>
    </div>
    <div class="container pt-5">
        <div class="row">
            <div class="col-xl-10 offset-xl-1">
                <h4 class="py-2">Click on any of the shades below to know more ..</h4>
                <div class="row">
                    <div class="col-lg-2 col-md-3 col-sm-4 col-6 mb-3 shade-single" (click)="openPopup(shade)" *ngFor="let shade of shades">
                        <div class="shade-color" [ngStyle]="{'background-color': shade}"></div>
                        <h5 class="shade-name">{{shade}}</h5>
                        <!-- <p class="shade-code">{{shade}}</p> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="color-popup">
    <div class="color-popup-inner">
        <div class="container position-relative">
            <div class="row m-0">
                <div class="col-lg-7 p-0" id="cpopup-img" [ngStyle]="{'background-color': currentCode}">
                    <img src="assets/images/home/color-popup-img.png" class="full" alt="Color Image">
                </div>
                <div class="col-lg-5 popup-body p-0">
                    <div class="popup-head d-flex justify-content-between py-4 pl-4 pr-md-5">
                        <h5 class="phead-title">Get<br>This<br>Shade</h5>
                        <div>
                            <div class="phead-color" [ngStyle]="{'background-color': currentCode}">
                                <h6 class="phead-code">{{currentCode}}</h6>
                            </div>
                        </div>
                    </div>
                    <div class="p-4">
                        <div class="popup-form">
                            <form [formGroup]="shadeForm">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <input type="text" formControlName="name" id="" class="form-control" placeholder="Name">
                                            <small class="text-danger" *ngIf="!shadeForm.get('name').valid && shadeForm.get('name').touched">Please Enter Your Name</small>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <input type="email" formControlName="email" id="" class="form-control" placeholder="E-mail">
                                            <small class="text-danger" *ngIf="!shadeForm.get('email').valid && shadeForm.get('email').touched">Please Enter Valid Email</small>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <input type="number" formControlName="mobile" id="" class="form-control" placeholder="Phone Number">
                                            <small class="text-danger" *ngIf="!shadeForm.get('mobile').valid && shadeForm.get('mobile').touched">Please Enter Phone Number</small>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <select formControlName="city" id="" class="form-control">
                                                <option value="">Select City/State</option>
                                                <option value="New Delhi">New Delhi</option>
                                                <option value="Mumbai">Mumbai</option>
                                                <option value="Lucknow">Lucknow</option>
                                                <option value="Bangalore">Bangalore</option>
                                                <option value="Hyderabad">Hyderabad</option>
                                            </select>
                                            <small class="text-danger" *ngIf="!shadeForm.get('city').valid && shadeForm.get('city').touched">Please Select Your City</small>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <input type="number" formControlName="pincode" id="" class="form-control" placeholder="Enter your Pincode">
                                            <small class="text-danger" *ngIf="!shadeForm.get('pincode').valid && shadeForm.get('pincode').touched">Please Enter Your Pincode</small>
                                        </div>
                                    </div>
                                    <div class="col-md-12 mt-4">
                                        <button class="commmon-btn primary" (click)="submitShadeForm()" [disabled]="!shadeForm.valid">Send</button>
                                    </div>
                                    <div class="col-md-12">
                                        <div *ngIf="formSuccess" class="mt-4">
                                            <div class="alert alert-success small">{{msg}}</div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="close-popup" (click)="closePopup()"><i class="fa fa-times mr-2"></i> Close</div>
        </div>
    </div>
</div>

<app-knowmore></app-knowmore>
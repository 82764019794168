import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Title, Meta } from '@angular/platform-browser';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  constructor(private api:ApiService, private titleService:Title, private metaService:Meta) { }
  metadetails:any;
  colorOption: OwlOptions  = {
    items: 1,
    autoplay: false,
    loop:false,
    margin:0,
    dots: true,
    nav: false
  }
  ngOnInit(): void {
    this.api.MetaAbout().subscribe(a=>{
      this.metadetails = a['response'][0]
      this.titleService.setTitle(this.metadetails.meta_title);
      this.metaService.addTags([
        {name: 'keywords', content: 'this.metadetails.meta_keywords'},
        {name: 'description', content: 'this.metadetails.meta_description'},
        {name: 'robots', content: 'index, follow'},
        {name: 'og:image', content: 'https://nexon.antsdigital.in/'+this.metadetails.image}

      ]);
    })
    
  }

  displayStyle = "none";
  openPopup() {
    this.displayStyle = "block";
    var audios = document.getElementsByTagName('video');
    for(var i = 0, len = audios.length; i < len;i++){
        audios[i].play();
    }
  }
  closePopup() {
    this.displayStyle = "none";
    var audios = document.getElementsByTagName('video');
    for(var i = 0, len = audios.length; i < len;i++){
        audios[i].pause();
        audios[i].currentTime=0;
    }
  }
  

}

<footer>
    <img src="assets/images/foot-globe.png" alt="" class="foot-globe">
    <div class="footer-top">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-3">
                    <img src="assets/images/logo.png" alt="" class="foot-logo">
                </div>
                <div class="col-lg-9">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="footer-widget">
                                <h4 class="widget-title">Navigation Links</h4>
                                <ul class="footer-list">
                                    <li><a routerLink="/">Home</a></li>
                                    <li><a routerLink="/about">About Us</a></li>
                                    <li><a routerLink="/vibrant-ideas">Vibrant Ideas</a></li>
                                    <li><a routerLink="/products">Products</a></li>
                                    <li><a routerLink="/downloads">Downloads</a></li>
                                    <li>News and Media</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="footer-widget">
                                <h4 class="widget-title">Quick Links</h4>
                                <ul class="footer-list">
                                    <li><a routerLink="/contact">Contact Us</a></li>
                                    <li><a routerLink="/branch-locator">Branch Locator</a></li>
                                    <li><a routerLink="/joinus">Join Us</a></li>
                                    <li><a routerLink="/faq">FAQ's</a></li>
                                    <li><a routerLink="/blogs">Blogs</a></li>
                                    <li><a routerLink="/certifications">Certifications</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="footer-widget">
                                <h4 class="widget-title">Follow Us</h4>
                                <ul class="footer-social">
                                    <li><a href="https://www.facebook.com/NexonPaintsVibrantIdeas/?modal=admin_todo_tour" target="_blank"><i class="fa fa-facebook"></i></a></li>
                                    <li><a href="https://www.linkedin.com/company/35634203/admin" target="_blank"><i class="fa fa-linkedin"></i></a></li>
                                    <li><a href="https://twitter.com/NexonPaints" target="_blank"><i class="fa fa-twitter"></i></a></li>
                                    <li><a href="https://www.instagram.com/nexonpaints/" target="_blank"><i class="fa fa-instagram"></i></a></li>
                                </ul>
                                <h4 class="widget-title">Newsletter</h4>
                                <div class="newsletter-form">
                                    <form [formGroup]="newsletterForm">
                                        <input type="text" placeholder="Enter you Email-Id" formControlName="email" class="foot-input">
                                        <label for="iagree" class="small col-12 p-0"><input type="checkbox" formControlName="i_agree" id="iagree" [checked]><span class="ml-2">I agree to receive notifications</span></label>
                                        <button class="foot-submit" [disabled]="!newsletterForm.valid" (click)="newsletterSubmit()">Subscribe</button>
                                        <div *ngIf="formSuccess" class="mt-4">
                                            <div class="alert alert-success small">{{msg}}</div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer-bottom">© {{currentYear}} Nexon Paints. All Rights Reserved | Designed by <a class="footer-link" href="https://antsdigital.in" target="_blank">Ants Digital</a></div>
</footer>

<div class="sticky-container">
    <ul class="sticky mb-0">
      <a routerLink="/contact">
        <img title="" alt="" src="assets/images/sticky-btn1.png" />
        <p>Enquiry</p>
      </a>
      <a routerLink="/branch-locator">
        <img title="" alt="" src="assets/images/sticky-btn2.png" />
        <p>Branch Locator</p>
      </a>
      <a routerLink="/paint-calculator">
        <img title="" alt="" src="assets/images/sticky-btn3.png" />
        <p>Paint Calculator</p>
      </a>
      <a routerLink="/faq">
        <img title="" alt="" src="assets/images/sticky-btn4.png" />
        <p>FAQ</p>
      </a>
    </ul>
  </div>
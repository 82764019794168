
<section class="bg-top">
    <img [src]="bannerImage" class="full" width="100%" height="auto">
</section>
<section class="brodcums">
    <div class="container">
        <ul>
            <li><a routerLink="/{{categorySlug}}">{{categoryName}}</a></li> <span>></span> <li>{{productName}}</li>
        </ul>
    </div>
</section>

<section class="contentproduct">
    <div class="container">
        <h3>{{productName}}</h3>
        <h5>{{SubName}}</h5>
        <div class="col-md-12 pad0 textinner" [innerHTML]="productDesc"></div>

        
        <h4 class="beniofitesd">{{Rangeheading}}</h4>
        <div class="row text-center img-done">
            <ng-container *ngFor="let prod of productRanges;let i=index">
                <div class="col-12 col-sm-12 col-md-3 col-xs-3 py-2"><img [src]="prod.range_image"><br/><span>{{prod.range_text}}</span></div>
            </ng-container>
        </div>

        <h4 class="beniofitesd" style="margin: 0 !important;">{{ctext}}</h4>
         <div class="name"><img [src]="vImage" class="py-3" alt=""></div> 
        <!-- <div class="col-md-10 pad0 ultext">
            <img [src]="range_image">
        </div> -->
        <!-- <div class="row text-center img-done">
            <ng-container *ngFor="let prod of productRanges;let i=index">
                <div class="col-12 col-sm-12 col-md-3 col-xs-3 py-2"><img [src]="prod.range_image"><br/><span>{{ctext}}</span></div>
            </ng-container>
        </div> -->

        <h3 class="beniofitesd">Benefits</h3>
        <div class="row text-center">
            <ng-container *ngFor="let ben of productBenefits;let i=index">
                <div class="col-6 col-sm-6 col-md-2 col-xs-2"><img [src]="ben.image"><br/><span>{{ben.text}}</span></div>
            </ng-container>
        </div>

        
    </div>
</section>
<section class="videas mt-2 pb-5" *ngIf="showVIdeas">
    <div class="container">
        <h3 class="videa-title mb-5">Vibrant Ideas</h3>
        <div class="row">
            <div class="col-md-10">
                <div class="v-slider" [ngStyle]="{'background-color': currentVarient}">
                    <img src="assets/images/home/trans-wall.png" class="full" alt="Idea 1">
                    <!-- <owl-carousel-o [options]="colorOption">
                        <ng-template carouselSlide>
                            <img src="assets/images/home/trans-wall.png" class="full" alt="Idea 1">
                        </ng-template>
                    </owl-carousel-o> -->
                </div>
                <div class="mt-4 d-flex align-items-center justify-content-end">
                    <span class="mr-2">Palette:</span>
                    <ul class="text-right paint-list">
                        <ng-container *ngFor="let vari of varients">
                            <li [ngStyle]="{'background-color': vari}" (click)="changeVar(vari)"></li>
                        </ng-container>
                    </ul>
                    <!-- <ul class="text-right paint-list">
                        <li style="background-color: #C6C8C9;" data-color="#C6C8C9"></li>
                        <li style="background-color: #28424F;" data-color="#28424F"></li>
                        <li style="background-color: #77868C;" data-color="#77868C"></li>
                        <li style="background-color: #60574E;" data-color="#60574E"></li>
                        <li style="background-color: #736F55;" data-color="#736F55"></li>
                    </ul> -->
                </div>
                <div class="row mt-5">
                    <div class="col-md-5 d-flex align-items-end offset-3">
                        <img src="assets/images/home/colors.png" alt="Colors" class="ml-3 mr-2">
                        <h3 class="color-head">2200 colors Available</h3>
                    </div>
                    <div class="col-md-4">
                        <a routerLink="/colors" class="commmon-big-btn light">Click Here</a>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</section>
<!-- <app-videa></app-videa> -->
<app-knowmore></app-knowmore>
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  newsletterForm:any;
  currentYear:any;
  constructor(private api:ApiService) {
    this.newsletterForm = new FormGroup({
      'i_agree': new FormControl('', Validators.required),
      'email': new FormControl('', [Validators.required, Validators.email])
    })
    this.currentYear = new Date().getFullYear();
  }
  msg:any;
  formSuccess:any;
  ngOnInit(): void {
  }
  newsletterSubmit(){
    console.log(this.newsletterForm.value)
    this.api.newsletterSubscribe(this.newsletterForm.value).subscribe(data=> {
      console.log(data);
      this.msg = data;
      this.msg = this.msg.msg;
      this.formSuccess = true;
      setTimeout(()=>{
        this.formSuccess = false
        this.newsletterForm.reset();
      }, 3000)
    })
  }

}

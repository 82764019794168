import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-allproducts',
  templateUrl: './allproducts.component.html',
  styleUrls: ['./allproducts.component.css']
})
export class AllproductsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    var toggleEle = document.querySelectorAll('#eco-count .countchage');
    if(toggleEle.length>0) {
      toggleEle.forEach(d=>{
        d.addEventListener('click',(e)=>{
          var t = d.getAttribute('data-target');
          toggleEle.forEach(f=>{
            f.classList.remove('active');
          })
          d.classList.add('active');
          document.querySelectorAll('#changebit .pro-list').forEach(x=>{
            x.classList.add('hidden');
          })
          document.querySelectorAll('#'+t)[0].classList.remove('hidden');
        })
      })
    }
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-certifications',
  templateUrl: './certifications.component.html',
  styleUrls: ['./certifications.component.css']
})
export class CertificationsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    var toggleEle = document.querySelectorAll('#eco-count .countchage');
    if(toggleEle.length>0) {
      toggleEle.forEach(d=>{
        d.addEventListener('click',(e)=>{
          var t = d.getAttribute('data-target');
          toggleEle.forEach(f=>{
            f.classList.remove('active');
          })
          d.classList.add('active');
          document.querySelectorAll('#Eco-Friendly-img .changeimgeco').forEach(x=>{
            x.classList.add('hidden');
          })
          document.querySelectorAll('#'+t)[0].classList.remove('hidden');
        })
      })
    }
  }

}

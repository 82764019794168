import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
@Component({
  selector: 'app-paintguide',
  templateUrl: './paintguide.component.html',
  styleUrls: ['./paintguide.component.css']
})
export class PaintguideComponent implements OnInit {

  constructor() { }
  colorOption: OwlOptions  = {
    items: 1,
    autoplay: false,
    loop:false,
    margin:0,
    dots: true,
    nav: false
  }
  ngOnInit(): void {
  }

}
